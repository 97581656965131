import addressBookApi from '../../api/address-book'

// initial state
const state = () => ({
    addressData: {},
    selectedShippingAddress: {},
    selectedBillingAddress: {}
    // all: []
})

// getters
const getters = {}

// actions
const actions = {
    setShippingAddress({ commit, state }, address) {
        commit('setShippingAddress', address)
    },

    setBillingAddress({ commit, state }, address) {
        commit('setBillingAddress', address)
    },

    setAddressData({ commit, state }, addressData) {
        commit('setAddressData', addressData)
    },

    setDefaultAddress({ commit, state }, address) {
        return new Promise((resolve, reject) => {
            addressBookApi.setDefaultAddressId(address.id)
                .then(response => {
                    if (response.success) {
                        commit('setShippingAddress', address)
                        resolve(response.addressData);
                    } else {
                        console.error('cant update default address')
                        reject();
                    }
                })
                .catch(errorResponse => {
                    console.error(errorResponse.errors)
                    reject(errorResponse)
                })
        })
    },

    createAddress: function ({commit, state}, address) {

        return new Promise((resolve, reject) => {
            addressBookApi.createAddress(address)
                .then(response => {
                    if (response.success) {
                        commit('addAddress', response.addressData);
                        resolve(response.addressData);
                    } else {
                        console.error('cant create address ')
                        reject();
                    }
                })
                .catch(errorResponse => {
                    console.error(errorResponse.errors)
                    reject(errorResponse)
                })
        })
    },

    updateAddress ({ commit, state }, address) {

        return new Promise((resolve, reject) => {
            addressBookApi.updateAddress(address)
                .then( response => {
                    if(response.success) {
                        commit('updateAddress', response.addressData)
                        resolve(response.addressData);
                    } else {
                        console.error('cant update address ' + address.addressId)
                        reject();
                    }
                })
                .catch(errorResponse => {
                    console.error(errorResponse.errors)
                    reject(errorResponse)
                })
        })

    },

    deleteAddress ({ commit, state }, address) {

        return new Promise((resolve, reject) => {
            addressBookApi.deleteAddress(address)
                .then( response => {
                    if(response.success) {
                        commit('deleteAddress', response.addressId)
                        resolve();
                    } else {
                        console.error('cant delete address ' + address.addressId)
                        reject();
                    }
                })
                .catch(errorResponse => {
                    console.error(errorResponse.errors)
                    reject(errorResponse)
                })
        })
    },
}

// mutations
const mutations = {
    setAddressData (state, addressData) {
        state.addressData = addressData
    },

    setShippingAddress(state, address) {
        state.selectedShippingAddress = address
    },

    setBillingAddress(state, address) {
        state.selectedBillingAddress = address
    },

    addAddress(state, address) {
        address.editMode = false;
        state.addressData.customerAddresses.push(address);
    },

    updateAddress(state, address) {
        let addressToUpdate = state.addressData.customerAddresses.find(customerAddress => {
            return customerAddress.id === address.id
        });

        addressToUpdate = address;
    },

    deleteAddress(state, addressId) {
        let addressToDelete = state.addressData.customerAddresses.find(customerAddress => {
            return customerAddress.id === addressId
        });

        state.addressData.customerAddresses.splice(state.addressData.customerAddresses.indexOf(addressToDelete), 1);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
