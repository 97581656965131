"use strict";

require('smoothscroll-polyfill').polyfill();

const defaults = {
    selectors: {
        arrowToTop: '.js-scroll-top',
    },
    settings: {
        scrollDuration: 400,
    }
};

export default function bindScrollTop() {
    let linkToTop = document.querySelector(defaults.selectors.arrowToTop);

    if (!linkToTop) return false;

    linkToTop.addEventListener('click', function (event) {
        event.preventDefault();
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    })
}
