const config = {
    wishlistUrl: "/admin/wishlist.php",
    addUrl: "?add_product&pid=157834{1}1321&suid=0&products_qty=1",
    removeUrl: "?action=update_product&delete_product=157834{1}1321",
    storageKey: 'sb_wishlist',
    priceTypes: {
        PRICETYPE_NORMAL: 0,
        PRICETYPE_SPECIAL: 1,
        PRICETYPE_SET: 2,
        PRICETYPE_CUSTOMERCARD: 3,
        PRICETYPE_VIP: 4,
    },
    defaultWishlist: {
        "user_identifier": "",
        "isLoggedIn": false,

        "wishlist": [],
    },
    wishlistSchema : {
        type: "object",
        properties: {
            user_identifier: {type: "string"},
            isLoggedIn: {type: "boolean"},
            wishlist: {type: "array", items: {type: "object"}},
        },
        required: ["wishlist"],
        additionalProperties: false,
    }
};

export default config;
