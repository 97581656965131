"use strict";

import axios from 'axios';

const defaults = {
    selectors: {
        baseInfoForm: '.js-baseinfo-form',
        countrySelect: '.js-country-select',
        zipInputContainer: '.js-zip-input-container',
        zipInput: '.js-zip-input',
        zipFormatHelp: '.js-zip-format-help'
    },
    classes: {
    }
};

const state = {
    baseInfoForm: null,
    countrySelect: null,
    zipInputContainer: null,
    zipInput: null,
    selectedCountryID: null,
    countryOptions: null,
    zipFormatHelp: null,
};

export function initBaseInfoForm() {
    if (!document.querySelector(defaults.selectors.baseInfoForm)) {
        return false
    }
    initialize();
}

function initialize () {
    let baseInfoForm = document.querySelector(defaults.selectors.baseInfoForm);

    if (!baseInfoForm) {
        return false;
    }

    if (baseInfoForm) {
        state.baseInfoForm = baseInfoForm;

        let countrySelect = baseInfoForm.querySelector(defaults.selectors.countrySelect);
        let zipInputContainer = baseInfoForm.querySelector(defaults.selectors.zipInputContainer);
        let zipInput = zipInputContainer.querySelector(defaults.selectors.zipInput);
        // let zipFormatHelp = zipInputContainer.querySelector(defaults.selectors.zipFormatHelp);

        if (countrySelect && zipInputContainer && zipInput) {
            state.countrySelect = countrySelect;
            state.zipInputContainer = zipInputContainer;
            state.zipInput = zipInput;
            state.zipInputHelpBase = state.zipInput.getAttribute('placeholder');
            // state.zipFormatHelp = zipFormatHelp;

            state.selectedCountryID = parseInt(state.countrySelect.value, 10) || 81;
            bindCountrySelect();

            axios.get('/lang/shipping_countries.json')
                .then(data => {
                    state.countryOptions = data.data;
                    updateZipInput();
                })

        }
    }
}

function bindCountrySelect() {

    state.countrySelect.addEventListener('change', function (event) {

        if (this.value !== "") {
            state.selectedCountryID = parseInt(this.value, 10);
            updateZipInput();
        }
    })
}

function updateZipInput() {

    let selectedCountry = getSelectedCountryFromId(state.selectedCountryID);

    // state.zipFormatHelp.innerText = selectedCountry.format_display;
    state.zipInput.setAttribute('placeholder', state.zipInputHelpBase + " " + selectedCountry.format_display);
    state.zipInput.setAttribute('pattern', preparePattern(selectedCountry.zip_regex))
    state.zipInput.setAttribute('maxlength', selectedCountry.format_display ? selectedCountry.format_display.length : false)
    state.zipInput.setAttribute('required', !!selectedCountry.zip_regex)
    if (!selectedCountry.zip_regex) {
        state.zipInput.removeAttribute('required')
    } else {
        state.zipInput.setAttribute('required', true)
    }
}

function getSelectedCountryFromId(id) {
    if(state.countryOptions.length <= 0) {
        return false;
    }
    return state.countryOptions.find(option => option.id === parseInt(id))

}

function preparePattern(zip_regex) {
    if(!zip_regex) {
        return ".*";
    }
    return zip_regex.substring(0, zip_regex.length - 1).substring(1);
}
