import newsletterSignupFormApi from '../../api/newsletter-signup'

// initial state
const state = () => ({
    customerData: {},
})

// getters
const getters = {}

// actions
const actions = {

    signup: function ({commit, state}, customerData) {

        return new Promise((resolve, reject) => {
            newsletterSignupFormApi.signup(customerData)
                .then(response => {
                    if (response.success) {
                        // console.log(response);
                        // debugger;
                        // commit('addAddress', response.addressData);
                        resolve(response);
                    } else {
                        console.error('cant sign up ')
                        reject();
                    }
                })
                .catch(errorResponse => {
                    console.error(errorResponse.errors)
                    reject(errorResponse)
                })
        })
    },
}

// mutations
const mutations = {
    //
    // addAddress(state, address) {
    //     address.editMode = false;
    //     state.addressData.customerAddresses.push(address);
    // },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
