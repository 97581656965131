"use strict";

import Flickity from 'flickity-imagesloaded';
import {addClass, hasClass, lang} from "./helpers";

const defaults = {
    selectors: {
        productsSlider: '.js-products-slider',
        sliderProduct: '.js-products-slider-product',
        buttonsContainer: '.js-products-slider-buttons',
    },
    classes: {
        initClass: 'flky-init',
        counterContainer: 'slideshow__counter',
        counterCurrent: 'current',
        counterDivider: 'divider',
        counterTotal: 'total',
    }
};

const state = {
    productSliders: []
};

function initialize (parentNode = document) {

    let productSliders = parentNode.querySelectorAll(defaults.selectors.productsSlider);

    if (!productSliders) {
        return false;
    }

    if (productSliders) {
        Array.from(productSliders).forEach(productSlider => {
            if(hasClass(productSlider, defaults.classes.initClass)) {
                return;
            }

            const parentEl = productSlider.parentNode;
            const sliderButtons = parentEl.querySelector(defaults.selectors.buttonsContainer);
            const slides = productSlider.querySelectorAll(defaults.selectors.sliderProduct);

            let slideCount = Math.ceil(slides.length / 2);

            let slideShow = {
                productSlider,
                slides,
                slideCount
            };

            slideShow.singleProductSlider = new Flickity( productSlider, {
                cellSelector: defaults.selectors.sliderProduct,
                prevNextButtons: true,
                wrapAround: true,
                imagesLoaded: true,
                percentPosition: false,
                groupCells: true,
                cellAlign: 'left',
				adaptiveHeight: true,
                arrowShape: 'M74.0173881,2.97621873 C75.3275373,4.2778437 75.3275373,6.38819473 74.0173881,7.68981971 L31.4301323,50 L74.0173881,92.3101802 C75.3275373,93.6118055 75.3275373,95.7221562 74.0173881,97.0237815 C72.7072395,98.3254062 70.5830683,98.3254062 69.2729194,97.0237815 L25.4557953,53.4917345 C23.5147349,51.5633028 23.5147349,48.4366973 25.4557953,46.5082656 L69.2729194,2.97621873 C70.5830683,1.67459376 72.7072395,1.67459376 74.0173881,2.97621873 Z',
                on: {
                    ready: function() {
                        slideShow.pager =  slideShow.productSlider.querySelector('.flickity-page-dots');

                        if (slideCount > 5) {
                            buildPagerCounter(slideShow);
                        }
                    },
                    change: function( index ) {
                        updatePagerCounter(slideShow, index);
                    }
                }
            });


            if(sliderButtons) {
                const prevButton = sliderButtons.querySelector('.flickity-prev-next-button.previous');
                if (prevButton) {
                    prevButton.addEventListener('click', e => { slideShow.singleProductSlider.previous( true )});
                }

                const nextButton = sliderButtons.querySelector('.flickity-prev-next-button.next');
                if (nextButton) {
                    nextButton.addEventListener('click', e => { slideShow.singleProductSlider.next( true )});
                }
            }
            addClass(productSlider, defaults.classes.initClass);
            addClass(parentEl, defaults.classes.initClass);
            state.productSliders.push(slideShow)
        });
    }
}

function buildPagerCounter(slideShow, currentIdx) {

    let counterContainer = document.createElement('div');
    addClass(counterContainer, defaults.classes.counterContainer);

    let counterCurrent = document.createElement('span');
    addClass(counterCurrent, defaults.classes.counterCurrent);

    let counterDivider = document.createElement('span');
    addClass(counterDivider, defaults.classes.counterDivider);
    counterDivider.innerText = lang === 'de' ? ' von ' : ' of ';

    let counterTotal = document.createElement('span');
    addClass(counterTotal, defaults.classes.counterTotal);

    counterCurrent.innerText = currentIdx ? currentIdx + 1 : 1;
    counterTotal.innerText = slideShow.slideCount;

    counterContainer.append(counterCurrent, counterDivider, counterTotal );

    addClass(slideShow.pager, 'counter');

    slideShow.pager.append(counterContainer);

    slideShow.counterContainer = counterContainer;
    slideShow.counterCurrent = counterCurrent;
    slideShow.counterDivider = counterDivider;
    slideShow.counterTotal = counterTotal;
    slideShow.hasCounter = true;
}

function updatePagerCounter(slideShow, currentIdx) {
    if (!slideShow.hasCounter) {
        return;
    }

    slideShow.counterCurrent.innerText = currentIdx ? currentIdx + 1 : 1;
}


export function initProductSliders(parentNode = document) {
    initialize(parentNode);
}
