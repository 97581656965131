import Vue from 'vue'
import Vuex from 'vuex'
import addressBook from './modules/address-book'
import signupForm from './modules/signup-form'
import newsletterSignupForm from './modules/newsletter-signup-form'

Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        addressBook,
        signupForm,
        newsletterSignupForm
    },
})
