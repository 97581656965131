import {bindPdpPceTrigger, bindTogglePCETrigger, collectPCELinks} from "./interaction";
import pceApi from "../../api/product-comparison";
import {addClass} from "../helpers";
import {updatePCECount} from "./interaction/header";
// import {walkButtons} from "../wishlist/interaction/buttons";
// import {initWishlistMethods} from "../wishlist";

export function initProductComparisonOnPdp () {
	console.log("init product comparison");

	let userData = window.Bittl.UserData;

	if (!userData) {
		console.warn('userdata missing');
		return false;
	}

	console.log(userData);



	initProductComparisonInteraction();
}


export function initProductComparisonInteraction () {
	// window.sbpce = initProductComparisonInteraction();
	//
	bindPdpPceTrigger();
}

export function initProductComparisonGlobal () {

	pceApi.getProductIds()
		.then(response => {
			console.log(response);
			// target.dataset.pceInteraction = "go-to-pce";
			// addClass(parent, "is-on-pce");
			updatePCECount(response.products.length);
			updatePCECountPDP(response.products.length);
		});
}

export function updatePCECountPDP(count) {
	let countEls = document.querySelectorAll('.js-pce-count-pdp');

	if (!countEls) {
		return false;
	}

	if (countEls) {
		Array.from(countEls).forEach(countEl => {
			let futureCount = count+1;
			countEl.textContent = `(${futureCount}/4)`
		});
	}
}
