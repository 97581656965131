export function updatePCECount(count) {
    let countEls = document.querySelectorAll('.js-pce-count');

    if (!countEls) {
        return false;
    }

    if (countEls) {
        Array.from(countEls).forEach(countEl => {

			countEl.textContent = count >= 1 ? count : '';
        });
    }
}
