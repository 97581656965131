"use strict";

export function metalang() {
  return document.querySelector('html[lang]') ? document.querySelector('html').getAttribute('lang').toLowerCase() : 'de';
}

export var lang = metalang();
export var mobilesettings = {
  Utils : {
    PHONE_TAG : "mobile",
    PHABLET_TAG : "phablet",
    BASKET_TAG : "basket",
    TABLET_TAG : "tablet",
    DESKTOP_TAG : "desktop",
    DESKTOP_LARGE_TAG : "wide",
    DESKTOP_ULTRAWIDE_TAG : "ultrawide",
  }
};

// Track breakpoints in JS
export function getResponsiveTag () {
  var tag = window.getComputedStyle(document.body,':after').getPropertyValue('content');
  tag = tag.replace( /"|'/g,'');   // Firefox bugfix
  return tag;
};

export function isMobile () {
  return getResponsiveTag() === mobilesettings.Utils.PHONE_TAG || getResponsiveTag() === mobilesettings.Utils.PHABLET_TAG;
};

export function isMobileBasket () {
  return getResponsiveTag() === mobilesettings.Utils.PHONE_TAG || getResponsiveTag() === mobilesettings.Utils.PHABLET_TAG || getResponsiveTag() === mobilesettings.Utils.BASKET_TAG;
};

export function isTablet () {
  return getResponsiveTag() === mobilesettings.Utils.TABLET_TAG;
};

export function isTouchDevice() {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}

export function decodeHTML (html) {
    let txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
}

export function nodeListToArray (nodelist) {
  return [].slice.call(nodelist);
}

export function tableFlip () {
  console.log('(╯°□°）╯︵ ┻━┻');
}

export function  randomIntFromInterval (min,max) {
  return Math.floor(Math.random()*(max-min+1)+min);
}

export function getLayout (layoutContainer) {
  var layout = document.querySelector(layoutContainer).length > 0 ?  document.querySelector(layoutContainer).data('layout') : false;
  return layout;
}

export function getScreenWidth () {
  return window.innerWidth;
}

export function getBaseUrl() {
  var baseurl;
  if (document.querySelector('base')) {
    baseurl = document.querySelector('base').prop('href');
  } else {
    if (window.location.protocol != "https:") {
      baseurl = 'http://' + window.location.hostname;
    } else {
      baseurl = 'https://' + window.location.hostname;
    }
  }
  return baseurl;
}

export function getUrlParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"), results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function normalizeClass(cls) {
    if( cls.indexOf('.') === 0) {
        cls = cls.substr(1);
    }
    return cls;
}

export function findAncestorByClass (el, cls) {
    cls = normalizeClass(cls);
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
}

export function addClass(el, cls) {
    cls = normalizeClass(cls);
    if (el.classList.contains(cls)) {
        return
    }
    el.classList.add(cls);
}

export function removeClass(el, cls) {
    cls = normalizeClass(cls);
    if (el.classList.contains(cls)) {
        el.classList.remove(cls);
    }
}

export function hasClass (el, cls) {
    cls = normalizeClass(cls);
    return el.classList.contains(cls)
}

export function toggleClass(el, cls) {
    cls = normalizeClass(cls);
    if (el.classList.contains(cls)) {
        removeClass(el, cls)
    } else {
        addClass(el, cls)
    }
}

export function addStyle(el, styleKey, styleValue) {
    let elStyles = el.style;

    elStyles[styleKey] = styleValue;
}

export function wrapElement(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
}

export function getWindowScrollPosition() {
  return {
    x: window.pageXOffset || document.documentElement.scrollLeft,
    y: window.pageYOffset || document.documentElement.scrollTop
  };
}


export function isElementInViewport (el) {
    var rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
}

export function scrollTo(element, to, duration) {
    if (duration <= 0) return;
    let difference = to - element.scrollTop;
    let perTick = difference / duration * 10;

    setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(element, to, duration - 10);
    }, 10);
}

export function encode_utf8(s) {
    return unescape(encodeURIComponent(s));
}

export function decode_utf8(s) {
    return decodeURIComponent(escape(s));
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
/**
 * Fixes SVGs attributes to include the window.location in case a base tag is present
 */
export function fixSVG () {
  /**
   * Current URL, without the hash
   */
  var baseUrl = window.location.href.replace(window.location.hash, "");

  /**
   *  Find all `use` elements with a namespaced `href` attribute, e.g.
   *  <use xlink:href="#some-id"></use>
   *
   *  See: http://stackoverflow.com/a/23047888/796152
   */
  [].slice.call(document.querySelectorAll("use[*|href]"))

  /**
   * Filter out all elements whose namespaced `href` attribute doesn't
   * start with `#` (i.e. all non-relative IRI's)
   *
   * Note: we're assuming the `xlink` prefix for the XLink namespace!
   */
    .filter(function(element) {
      return (element.getAttribute("xlink:href").indexOf("#") === 0);
    })

    /**
     * Prepend `window.location` to the namespaced `href` attribute value,
     * in order to make it an absolute IRI
     *
     * Note: we're assuming the `xlink` prefix for the XLink namespace!
     */
    .forEach(function(element) {
      element.setAttribute("xlink:href", baseUrl + element.getAttribute("xlink:href"));
    });

  /** Clip-path attributes */
  [].slice.call(document.querySelectorAll("svg [clip-path]"))
    .filter(function(element) {
      return (element.getAttribute("clip-path").indexOf("url(#") === 0);
    })
    .forEach(function(element) {
      let oldVal = element.getAttribute("clip-path").slice(4,-1);
      let newVal = "url(" + baseUrl + oldVal +  ")";
      element.setAttribute("clip-path", newVal);
    });

  /** Mask attributes */
  [].slice.call(document.querySelectorAll("svg [mask]"))
    .filter(function(element) {
      return (element.getAttribute("mask").indexOf("url(#") === 0);
    })
    .forEach(function(element) {
      let oldVal = element.getAttribute("mask").slice(4,-1);
      let newVal = "url(" + baseUrl + oldVal +  ")";
      element.setAttribute("mask", newVal);
    });

  /** Pattern attributes */
  [].slice.call(document.querySelectorAll("svg [pattern]"))
    .filter(function(element) {
      return (element.getAttribute("pattern").indexOf("url(#") === 0);
    })
    .forEach(function(element) {
      let oldVal = element.getAttribute("pattern").slice(4,-1);
      let newVal = "url(" + baseUrl + oldVal +  ")";
      element.setAttribute("pattern", newVal);
    });

  /** Fill attributes */
  [].slice.call(document.querySelectorAll("svg [fill]"))
    .filter(function(element) {
      return (element.getAttribute("fill").indexOf("url(#") === 0);
    })
    .forEach(function(element) {
      let oldVal = element.getAttribute("fill").slice(4,-1);
      let newVal = "url(" + baseUrl + oldVal +  ")";
      element.setAttribute("fill", newVal);
    });
}

export function loadAsyncJs(URL, callback) {
    let documentTag = document, tag = 'script', object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];

    if (URL.indexOf("http") === -1) {
        object.src = '//' + URL;
    } else {
        object.src = URL;
    }
    object.setAttribute('async','');
    if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
    scriptTag.parentNode.insertBefore(object, scriptTag);
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function debounce (fn, delay) {
	var timeoutID = null
	return function () {
		clearTimeout(timeoutID)
		var args = arguments
		var that = this
		timeoutID = setTimeout(function () {
			fn.apply(that, args)
		}, delay)
	}
}
