"use strict";

import Flickity from 'flickity-imagesloaded';
import {addClass, hasClass} from "./helpers";

const defaults = {
    selectors: {
        slideshow: '.js-image-slideshow',
        slide: '.js-image-slide',
    },
    classes: {
      initClass: 'flky-init'
        // flyoutActiveClass: 'is-open'
    },
    autoplaySpeed: 5000,

};

const state = {
    slideshows: []

};

let panelWrapper, flkty, nextButton, prevButton, menuButton, menu;

function initialize (parentNode = document) {
    let slideshows = parentNode.querySelectorAll(defaults.selectors.slideshow);

    if (!slideshows) {
        return false;
    }

    if (slideshows) {
        Array.from(slideshows).forEach(slideshow => {
            if(hasClass(slideshow, defaults.classes.initClass)) {
                return;
            }

            let autoplay = slideshow.dataset['autoplay'] ? defaults.autoplaySpeed : false;

            let singleSlideshow = new Flickity( slideshow, {
                cellSelector: defaults.selectors.slide,
                wrapAround: true,
                autoPlay: autoplay,
                imagesLoaded: true,
             });

            addClass(slideshow, defaults.classes.initClass);

            state.slideshows.push(singleSlideshow)
        });
    }
}

export function initImageSlideshows() {
    initialize();
}
