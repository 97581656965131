export function updateWishlistCount(wishlist_count) {
    let wishlistCountEls = document.querySelectorAll('.js-wishlist-count');

    if (!wishlistCountEls) {
        return false;
    }

    if (wishlistCountEls) {
        Array.from(wishlistCountEls).forEach(wishlistCountEl => {

            wishlistCountEl.textContent = wishlist_count >= 1 ? wishlist_count : '';
        });
    }
}
