"use strict";

import {hasClass, toggleClass} from "./helpers";

const defaults = {
    selectors: {
        arrowToTop: '.js-trigger-langswitch-flyout',
    },
    settings: {
        scrollDuration: 400,
        openClass: 'is-open',
    }
};

export default function bindLangSwitchFlyout() {
    let triggerLink = document.querySelector(defaults.selectors.arrowToTop);

    if (!triggerLink) return false;

    triggerLink.addEventListener('click', function (event) {
        console.log(this);
        console.log(event);
        if(hasClass(this, 'is-current')) {
            event.preventDefault();
        }
        toggleClass(triggerLink, defaults.settings.openClass);
    })
}
