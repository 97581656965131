"use strict";

import {findAncestorByClass,hasClass} from "../helpers";

const defaults = {
	selectors: {
		printLink: '.js-print-page',
	},
	settings: {
		scrollDuration: 400,
	}
};

export function bindPrintLinks() {

	console.log("printlink");
	document.addEventListener('click', (event) => {
		let target = hasClass(event.target, defaults.selectors.printLink) ? event.target : false;
		if(!target) {
			let tempTarget = findAncestorByClass(event.target, defaults.selectors.printLink);
			target = tempTarget ? tempTarget : false
		}
		if(target) {
			event.preventDefault();
			window.print();

		}
	})

}
