"use strict";

import {findAncestorByClass, hasClass} from "./helpers";

const defaults = {
    selectors: {
        analyticsLink: '.js-analytics-track',
    },
    settings: {
    }
};

export function trackEvent(eventTarget) {
    let category = eventTarget.dataset['cat'];
    let action = eventTarget.dataset['action'];
    let label = eventTarget.dataset['label'];

    console.table([eventTarget.pathname, category, action, label]);

    if(typeof dataLayer !== 'undefined') {
        dataLayer.push({'event': 'ClickAnalyticsJS', 'analyticsCat': category, 'analyticsAction': action, 'analyticsLabel': label})
    }
    if(typeof _paq !== 'undefined') {
        _paq.push(['trackEvent', category, action, label]);
    }

}

export function trackCartEvent(basketData) {
	var _mtm = window._mtm = window._mtm || [];
	if(typeof _mtm !== 'undefined') {
		// An addEcommerceItem push should be generated for each cart item, even the products not updated by the current "Add to cart" click.
		var total = 0.0;
		var mtmItems = [];
		basketData.items.forEach(item => {
			item.products.forEach(product => {

				// var	price = parseFloat(product.price.replace('.','').replace(',', '.').replace("€", '').trim()); // replace , thousands separator
				total = total + (product.price_raw * parseInt(item.quantity));
				mtmItems.push({
					'productSKU': product.ean,
					'productName': product.brandName + ' - ' + product.productsName + ' ('+product.selectedSize+')',
					'categoryName': '',
					'productQuantity': item.quantity,
					'productPrice': product.price_raw
				});
				// _paq.push(['addEcommerceItem',
				// 	product.productModel, // (Required) productSKU
				// 	product.brandName + ' - ' + product.productsName, // (Optional) productName
				// 	'', // (Optional) productCategory
				// 	price, // (Recommended) price
				// 	item.quantity // (Optional, defaults to 1) quantity
				// ]);
			});

		});


		// _paq.push(['trackEcommerceCartUpdate', total]);
		_mtm.push({
			'event': 'add-to-cart',
			'ecommerce': {
				'add_to_cart': {
					'items': mtmItems,
					'total': parseFloat(total).toFixed(2)
				}
			}
		});
		console.log(_mtm);
	}
}

export function trackGenericEvent(googleData = [], matomoData = []) {

	if(typeof dataLayer !== 'undefined') {
		Object.keys(googleData).forEach(event => {
			console.log('DataLayerPush Google');
			console.table(googleData[event]);
			dataLayer.push(googleData[event]);
		});
	}
	if(typeof _mtm !== 'undefined') {
		Object.keys(matomoData).forEach(event => {
			console.log('DataLayerPush Matomo');
			console.table(matomoData[event]);
			_mtm.push(matomoData[event]);
		});
	}
}

export function sendEvent(category, action, label) {
    console.table([category, action, label]);

    if(typeof ga !== 'undefined') {
        dataLayer.push({'event': 'ClickAnalyticsJS', 'analyticsCat': category, 'analyticsAction': action, 'analyticsLabel': label})
    }
    if(typeof _paq !== 'undefined') {
        _paq.push(['trackEvent', category, action, label]);
    }
}

function bindWindow() {
    document.addEventListener('click', (event) => {
        let target = event.target.classList.contains('js-analytics-track') ? event.target : false;
        if(!target) {
            let tempTarget = findAncestorByClass(event.target, 'js-analytics-track');
            target = tempTarget ? tempTarget : false
        }
        if(target) {
            trackEvent(target)
        }
    })
}

export default function initAnalyticsTracking() {
    bindWindow();
}
