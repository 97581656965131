import axios from 'axios';
import {handleError} from "./_shared";

const settings = {
    baseUrl: '/api/address_book.php',
}

export default {
    createAddress(address) {

        return new Promise((resolve, reject) =>
            axios.post(settings.baseUrl,
                address,
                {
                    params: {
                        action: 'create',
                        customerId: address.customerId
                    }
                })
                .then(response => resolve(response.data))

				.catch(function (error) {
					handleError(error, reject);
				})
        )
    },

    updateAddress(address) {
        return new Promise((resolve, reject) =>
            axios.post(settings.baseUrl,
                address,
                {
                    params: {
                        action: 'update',
                        addressId: address.id,
                    }
                })
                .then(response => resolve(response.data))

				.catch(function (error) {
					handleError(error, reject);
				})
        )
    },

    deleteAddress(address) {
        return new Promise((resolve, reject) =>
            axios.get(settings.baseUrl, {
                params: {
                    action: 'delete',
                    addressId: address.id,
                    customerId: address.customerId
                }
            })
                .then(response => resolve(response.data))

				.catch(function (error) {
					handleError(error, reject);
				})
        )
    },

    setDefaultAddressId(addressId) {
        return new Promise((resolve, reject) =>
            axios.get(settings.baseUrl, {
                params: {
                    action: 'useAddress',
                    addressId: addressId,
                }
            })
                .then(response => resolve(response.data))

				.catch(function (error) {
					handleError(error, reject);
				})
        )
    },


}
