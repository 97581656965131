import {addClass, findAncestorByClass, removeClass, toggleClass} from "../../helpers";
import pceApi from "../../../api/product-comparison";
import {updatePCECount} from "./header";
import {updatePCECountPDP} from "../index";


export function bindPdpPceTrigger() {

	document.addEventListener('click', (event) => {
		let target = event.target.classList.contains('js-product-comparison-button-pdp') ? event.target : false;
		if(!target) {
			let tempTarget = findAncestorByClass(event.target, 'js-product-comparison-button-pdp');
			target = tempTarget ? tempTarget : false
		}
		if(target) {
			let parent = findAncestorByClass(target, 'js-product-comparison-button-group');
			let warning = document.querySelector('.js-pce-count-warning');

			let interactionType = target.dataset.pceInteraction;
			let configId = target.dataset.configId;
			let templateId = target.dataset.templateId;


			switch (interactionType) {
				case "add":
					pceApi.addProduct(configId, templateId)
						.then(response => {
							console.log(response);
							if(response.status === 403) {
								removeClass(warning, 'u-hide');
								return false;
							}
							addClass(parent, "is-on-pce");
							updatePCECount(response.products.length);
							updatePCECountPDP(response.products.length);
						}).catch(error => {
							console.log(error);
						});
					break;
				case "remove":
					pceApi.removeProduct(configId, templateId)
						.then(response => {
							console.log(response);
							removeClass(parent, 'is-on-pce');
							updatePCECount(response.products.length);
							updatePCECountPDP(response.products.length);
							// parent.querySelector('.js-product-comparison-button-pdp[data-pce-interaction="add"]').dataset.pceInteraction = "add";
						});
					break;
				case "go-to-pce":
					let targetUrl = new URL(window.location.origin);
					targetUrl.pathname = target.dataset.href;
					window.location = targetUrl;
					break;
				default:
					break;
			}
		}
	})
}
