"use strict";

import { lang } from '../helpers'
import axios from 'axios';
import Vue from 'vue';
import SignupForm from './SignupForm.vue';
import VueI18n from "vue-i18n";
import store from './../../store';

const defaults = {
    selectors: {
        container: '.js-signup-form',
        // searchForm: '.js-search-form',
        // searchInput: '.js-search-input',
    }
};

export function initSignupForm () {

    console.log("signup form");

    if (!document.querySelector(defaults.selectors.container)) {
        return false;
    }

    // const searchWordFromEl = document.querySelector(defaults.selectors.searchForm).dataset.searchWord;

    const messages = {
        de: require('../../../lang/lang_german'),
        en: require('../../../lang/lang_english'),
    }

    const i18n = new VueI18n({
        locale: lang, // set locale
        messages, // set locale messages
    })


    let registerForm = new Vue({
        el: '.js-signup-form',
        store,
        i18n,
        template: '<signup-form></signup-form>',
        components: { SignupForm },
    });
}
