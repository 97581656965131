"use strict";

export default function initDebugger() {

  let debuggerToggle = document.querySelector('#js-debugger-toggl');
  let debuggerStats = document.querySelector('#debugger-stats');

  if (!debuggerToggle) {
    return false;
  }

  debuggerToggle.addEventListener('click', function (e) {
    e.preventDefault();
    debuggerStats.classList.toggle('is-hidden')
  })

}
