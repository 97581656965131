import productsApi from "../api/products";
import {addClass, hasClass, removeClass} from "./helpers";
import {closeFlyout} from "./product-nav";
import getLangStrings from "./langstrings";
import pceApi from "../api/product-comparison";

let pceProducts;
let hasPceProducts = false;
let hasColorVariants = false;

const langStrings = getLangStrings();

function collectProducts() {

	return new Promise(async (resolve, reject) => {

		let newProducts = document.querySelectorAll('.fl-content-container .fl-product:not(.bt-product-init)');

		let newProductInfos = Array.from(newProducts).map(product => {
			let setLink = product.querySelector('.fl-product-inner-wrapper .fl-badges-container .fl-badge-set-container');
			let productData = {
				node: product,
				product_id: parseInt(product.dataset.productId),
				// link: '',
				is_set: !!setLink,
				set_link_node: setLink ? setLink : '',
				set_link: '',
				sku: null,
				url: product.querySelector('.fl-product-image-link').href,
			};

			let pceContainer = product.querySelector('.fl-product-comparison-container.js-product-comparison-toggle');

			if (pceContainer) {
				hasPceProducts = true;
				productData.pceContainer = pceContainer;
				productData.pceTemplate = parseInt(pceContainer.dataset.templateId);
			}

			return productData;

		});

		productsApi.getSetLinks(newProductInfos)
			.then(responseData => {
				console.log(responseData);
				// debugger;
				newProductInfos.forEach(infoObj => {
					let responseMatch = responseData.data.find(item => item.product_id === infoObj.product_id);
					if (responseMatch) {
						infoObj.set_link = responseMatch.link;

						infoObj.colors = responseMatch.colors;
						if(responseMatch.colors && responseMatch.colors.length > 1) {
							hasColorVariants = true;
						}
						infoObj.sku = responseMatch.sku;
						console.log(infoObj);
					}


				});
				resolve(newProductInfos)
			})
			.catch(function (error) {
				console.warn(error);
				reject(error);
			})
	});
}


export async function walkProducts() {
	let newProductInfos = await collectProducts();

	if (hasPceProducts) {
		pceProducts = await pceApi.getProductIds();
		pceProducts = pceProducts.products;
	}

	newProductInfos.forEach(productInfo => {
		if (hasClass(productInfo.node, 'bt-product-init')) {
			return;
		}
		// 1. wishlist buttons to all products
		// 2. add product comparison checkbox
		// 3. set links to all set products
		// 4. product color variants to all products
		// 5. product ratings to all products


		// [1]. wishlist buttons to all products
		let markersContainer = productInfo.node.querySelector('.fl-badges-container');

		if (!markersContainer) {
			markersContainer = document.createElement('div');
			addClass(markersContainer, 'fl-badges-container');

			let imageContainer = productInfo.node.querySelector('.fl-product-image');

			imageContainer.parentNode.insertBefore(markersContainer, imageContainer.nextSibling);
		}

		addClass(markersContainer, '.product-listing__product__markers');

		let wishlistButtonContainer = document.createElement('span');
		wishlistButtonContainer.innerHTML = `<button class="product-marker is-wishlist-button icon-only js-product-wishlist-button js-analytics-track" data-cat="Wunschliste" data-action="Listing" data-label="add/remove" type="button" data-config-id="${productInfo.product_id}" >
                <span class="icon-holder">
                    <svg class="wishlist-icon is-for-active" width="24" height="24"><use xlink:href="#icon-icons-wishlist-active"></use></svg>
                    <svg class="wishlist-icon is-for-inactive" width="24" height="24"><use xlink:href="#icon-icons-wishlist-inactive"></use></svg>
                </span>
            </button>`

		let wishlistButton = wishlistButtonContainer.querySelector('.js-product-wishlist-button');

		markersContainer.appendChild(wishlistButton);

		// [2] add product comparison checkbox

		if(productInfo.pceContainer) {

			let isOnPce = pceProducts.find(pceProduct => {
				return pceProduct === productInfo.product_id
			});

			let pceCheckboxContainer = document.createElement('span');
			addClass(pceCheckboxContainer, 'styled-checkbox-container');
			addClass(pceCheckboxContainer, 'button');
			addClass(pceCheckboxContainer, 'js-product-comparison-container-listing');
			pceCheckboxContainer.innerHTML = `<input type="checkbox" class="js-product-comparison-toggle-input" id="list-pce-toggle-${productInfo.product_id}" data-config-id="${productInfo.product_id}" data-template-id="${productInfo.pceTemplate}" data-pce-interaction="toggle" >
<label for="list-pce-toggle-${productInfo.product_id}" class="">
	<span >${langStrings.components.product_comparison.listing_toggle}</span>
</label>`;

			let pceInput = pceCheckboxContainer.querySelector('.js-product-comparison-toggle-input');
			if(isOnPce) {
				addClass(pceCheckboxContainer, 'is-on-pce');
				pceInput.setAttribute('checked', 'checked');
			}

			let pceLink = document.createElement('a');
			pceLink.href = '/product-comparison.php';
			addClass(pceLink, 'product-comparison-button');
			addClass(pceLink, 'button');
			pceLink.title = langStrings.components.product_comparison.go_to_comparison;
			pceLink.innerHTML = `<span class="icon-holder pce-icon"> <svg class="" width="32" height="32"><use xlink:href="#icon-icons-product-comparison"></use></svg> </span>`;

			pceLink.addEventListener('click', function (e) {
				e.stopImmediatePropagation();
				e.stopPropagation();
			})

			productInfo.pceContainer.appendChild(pceCheckboxContainer);
			productInfo.pceContainer.appendChild(pceLink);
		}

		// this can be used for multiple things that need to be added
		let productInner = productInfo.node.querySelector('.fl-product-inner-wrapper');


		// [4]. product color variants to all products

		if (hasColorVariants) {
			if(productInfo.colors && productInfo.colors.length > 0) {
				let colorVariantsContainer =  document.createElement('div');
				addClass(colorVariantsContainer, 'product-color-variants');

				let firstBatch;
				let secondBatch;
				if(productInfo.colors.length > 4) {
					firstBatch = productInfo.colors.slice(0, 4);
					secondBatch = productInfo.colors.slice(4);
				} else {
					firstBatch = productInfo.colors;
					// secondBatch = null;
				}
				colorVariantsContainer.innerHTML = `${firstBatch.map((color, index) => {
					let content = `<a href="${color.url}" class="product-color-variant-link" title="${color.name}" data-index="${index}">
							<img class="product-color-variant-image" src="https://media2.sport-bittl.com/images/product_images/original_images/${color.image}?width=44&progressive=1" alt="">
						</a>`;
					return content;
				}).join('')}`;
				if(productInfo.colors.length > 2) {

					let moreLink = document.createElement('a');
					addClass(moreLink, 'product-color-variant-link');
					addClass(moreLink, 'is-show-more');
					moreLink.href = productInfo.url;
					moreLink.dataset.count = productInfo.colors.length;
					moreLink.innerHTML = `<span class="product-color-variant-more u-hide--wd-up">+${productInfo.colors.length - 2}</span>`;

					if( productInfo.colors.length > 3) {
						addClass(moreLink, 'show-on-mobile');
					}

					if(productInfo.colors.length > 4) {
						moreLink.innerHTML += `<span class="product-color-variant-more u-hide--wd">+${productInfo.colors.length - 4}</span>`;
					} else {
						addClass(moreLink, 'u-hide--wd-up');
					}


					colorVariantsContainer.append(moreLink);


				}

				productInner.querySelector('.fl-product-infos').prepend(colorVariantsContainer);

				productInner.querySelectorAll('.product-color-variant-link').forEach(link => {
					link.addEventListener('click', function (e) {
						e.preventDefault();
						e.stopPropagation();
						window.location = link.href;
					});
				});
			}
		}

		// @TODO: enable this when we have color variants

		// [3]. set links to all set products

		if (productInfo.is_set) {
			productInfo.set_link_node.children[0].href = productInfo.set_link;

			console.log("remove node");
			// console.log(infoObj);
			if (productInfo.set_link_node.parentNode) {
				productInfo.set_link_node.parentNode.removeChild(productInfo.set_link_node);
			}

			if (productInfo.set_link) {
				productInner.appendChild(productInfo.set_link_node);
			}

		}

		// [5]. product ratings to all products

		let eTrustedWidget = document.createElement('etrusted-widget');
		addClass(eTrustedWidget, 'product-rating');
		eTrustedWidget.setAttribute('data-etrusted-widget-id', 'wdg-0756a568-a619-4e9a-9e86-4ef02e3a10b1');
		eTrustedWidget.setAttribute('data-sku', productInfo.sku);

		productInner.appendChild(eTrustedWidget);

		// Set init class
		addClass(productInfo.node, 'bt-product-init');
	})
}

export function hideHeroContent() {
	const flResult = document.querySelector('.fl-result');
	const heroContent = document.querySelector('.hero-content');
	if(!flResult || !heroContent) return;

	if (window.location.hash.indexOf("#search:") === 0) {
		addClass(heroContent, 'u-visuallyhidden');
	} else {
		removeClass(heroContent, 'u-visuallyhidden');
	}
}

export function handleFindoInit() {
	console.log("handleFindoInit");
	if(!hasClass(document.querySelector('body'), 'pagetype--product-listing')) {
        console.log("bind now");
        document.addEventListener("findologicFinish", function (e) {
            console.log(e);
            console.log('findofinish');
            document.querySelector('body').dataset.isFindoFinish = "true";

            closeFlyout();
			walkProducts().then(r => {console.log(r);});
			hideHeroContent();
        });
    }
}
