import config from "../config";
import {updateWishlistCount} from "../interaction/header";
import {default as storage} from "./localstorage";
// const Ajv = require('ajv').default;
import wishlistApi from "../../../api/wishlist";
import merge from 'deepmerge';


// const options = {
//     unicodeRegExp: false,
//     code: { es5: true },
// };
// const ajv = new Ajv({unicodeRegExp: false, code: {es5: true}}) // options can be passed, e.g. {allErrors: true}
// const validateJson = ajv.compile(config.wishlistSchema);

const validateJson = require('./validate_schema').default;
// const Bar = require('./validate-cjs')
export const userData = window.Bittl.UserData;

export function getStoredWishlist() {
    if(!storage.has(config.storageKey)) {
        storage.set(config.storageKey, config.defaultWishlist)
    }
    let storedValue = storage.get(config.storageKey);
    let jsonValid =  validateJson(storedValue);
    if(!jsonValid) {
        console.error('wishlist invalid')
        console.error(validateJson.errors)
        return false;
    }
    return storedValue;
}

export function storeWishlist(wishlist, save = true) {
    let jsonValid =  validateJson(wishlist);
    if(!jsonValid) {
        console.error(validateJson.errors)
        return false;
    }
    storage.set(config.storageKey, wishlist);
    let wishlist_count = wishlist.wishlist.length;
    updateWishlistCount(wishlist_count);

    if(save) {
        if(userData.isLoggedIn && wishlist.isLoggedIn && wishlist.wishlist.length >= 0) {
            let dbStoredWishlist = wishlistApi.saveWishlist(wishlist)
            console.log(dbStoredWishlist);
        }
    }
    return true;
}

export function resetWishlist() {
    storage.set(config.storageKey, config.defaultWishlist)
    let wishlist_count = 0;
    updateWishlistCount(wishlist_count);
    return true;
}

export function mergeWishlists(wl1, wl2) {
    let result = merge(wl1, wl2);

    console.log(result);

    return result;
}

export function getWishlistProducts() {

}
