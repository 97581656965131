import axios from 'axios';
import {handleError} from "./_shared";

const settings = {
    baseUrl: '/api/products.php',
	alterNativeBaseUrl: '/ax_product.php',
}

export default {
    getSetLinks(productsData) {
        return new Promise((resolve, reject) =>
            axios.post(settings.baseUrl,
                productsData,
                {
                    params: {
                        action: 'findologic_products_data',
                        // customerId: address.customerId
                    }
                })
                .then(response => resolve(response.data))
				.catch(function (error) {
					handleError(error, reject);
				})
        )
    },

    enrichProductsData(productsData) {
        return new Promise((resolve, reject) =>
            axios.post(settings.baseUrl,
                productsData,
                {
                    params: {
                        action: 'findologic_products_data',
                        // customerId: address.customerId
                    }
                })
                .then(response => resolve(response.data))
				.catch(function (error) {
					handleError(error, reject);
				})
        )
    },

    getSizes(productsId) {
        return new Promise((resolve, reject) =>
			axios({
				method: 'get',
				url: settings.alterNativeBaseUrl,
				params: {
					action: 'getProductSizes',
					products_id: productsId
				},
			}).then(response => resolve(response.data))
				.catch(function (error) {
					handleError(error, reject);
                })
        )
    }
}
