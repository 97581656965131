"use strict";

export function metalang() {
  let metalang = document.querySelector('html[lang]') ? document.querySelector('html').getAttribute('lang').toLowerCase() : false;
  if (!metalang || metalang.trim() === "") {
    return 'de'
  }
  return metalang;
}

export var lang = metalang();


export function tableFlip () {
  console.log('(╯°□°）╯︵ ┻━┻');
};

export function  randomIntFromInterval (min,max) {
  return Math.floor(Math.random()*(max-min+1)+min);
};

export function getScreenWidth () {
  return window.innerWidth;
};

function normalizeClass(cls) {
    if( cls.indexOf('.') === 0) {
        cls = cls.substr(1);
    }
    return cls;
}

export function findAncestorByClass (el, cls) {
  cls = normalizeClass(cls);
  while ((el = el.parentElement) && !el.classList.contains(cls));
  return el;
}

export function addClass(el, cls) {
    cls = normalizeClass(cls);
  if (el.classList.contains(cls)) {
    return
  }
  el.classList.add(cls);
}

export function removeClass(el, cls) {
    cls = normalizeClass(cls);
  if (el.classList.contains(cls)) {
    el.classList.remove(cls);
  }
}

export function hasClass (el, cls) {
    cls = normalizeClass(cls);
    return el.classList.contains(cls)
}

export function toggleClass(el, cls) {
    cls = normalizeClass(cls);
    if (el.classList.contains(cls)) {
      removeClass(el, cls)
    } else {
      addClass(el, cls)
    }
}

export function addStyle(el, styleKey, styleValue) {
    let elStyles = el.style;

    elStyles[styleKey] = styleValue;
}

export function wrapElement(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
}
