"use strict";

import {hasClass} from "./helpers";
import {toggleAccordionElement} from "./accordions";

require('smoothscroll-polyfill').polyfill();

const defaults = {
    selectors: {
        scrollLink: '.js-scroll-link',
        jumpScrollLink: '.js-jump-and-scroll',
		accordionElement: '.js-accordion-element',
    },
    settings: {
        scrollDuration: 400,
    }
};

export default function bindScrollLinks(parentNode = document) {
    let allScrollLinks = parentNode.querySelectorAll(defaults.selectors.scrollLink);

    if (!allScrollLinks) return false;

    Array.from(allScrollLinks).forEach(scrollLink => {

        scrollLink.addEventListener('click', function (event) {
            const link = event.currentTarget;
            console.log(link);

            let targetHash = link.hash || link.dataset.target;

            if (!targetHash) {
                return false;
            }

            let target = document.querySelector(targetHash);

            if (!target) {
                if(hasClass(link, defaults.selectors.jumpScrollLink)) {
                    console.log("should jump");
                }
                return false;
            }

            event.preventDefault();

			if(hasClass(target, defaults.selectors.accordionElement)) {
				toggleAccordionElement(target);
			}

            target.scrollIntoView({ behavior: 'smooth', block: 'start'  });
        })
    });
}
