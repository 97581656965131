const btlang = {
    langCode: "en-EN",
    form_validation: {
        missing_gender: "Please enter your gender",
        missing_gender_fieldname: "Gender",
        missing_firstname: "Please enter your first name",
        missing_firstname_fieldname: "First name",
        missing_lastname: "Please enter your last name",
        missing_lastname_fieldname: "Last name",
        missing_street: "Please enter your street",
        missing_street_fieldname: "Street",
        missing_postalcode: "Please enter your postal code",
        missing_postalcode_fieldname: "Postal code",
        missing_city: "Please enter your city",
        missing_city_fieldname: "City",
        missing_telephone: "Please enter your telephone",
        missing_telephone_fieldname: "Telephone",
        invalid_postalcode: "Your postal code is invalid",
        invalid_postalcode_short: "Postal code invalid",
        missing_streetnumber: "Please enter your street number",
        missing_streetnumber_fieldname: "Street number",
        missing_email: "Please enter your Email",
        missing_email_fieldname: "Email",
        wrong_email: "Please enter a valid Email",
        missing_password: "Please enter a password",
        missing_password_fieldname: "Password",
        missing_password_confirm: "Please confirm your password",
        missing_password_confirm_fieldname: "Password confirmation",
        password_no_match: "The passwords must match",
        multiple_errors: "Please check your entries",
    },
    messages: {
        errors: {
            paymentCreditCard: "Sorry, this did not work, please check your entries and try again.",
            paymentWrongBrand: "The credit card used is currently not supported. Please choose another credit card or payment method.",
            formError: "Please check your details here:",
            //       checkoutAddress : "The following boxes have to be filled!",
            //       invalidEmail: "Please enter a valid e-mail address!",
            //       skiMountingSole: "The sole length has to be a three digit (integer) number!"
        }
    },
    headings: {
        search: {
            products: 'Products',
            brands: 'Brands',
            categories: 'Categories',
            pages: 'Pages',
        },
        payment_methods_pay_with: 'Pay safely with'
    },
    helpers: {
        e_g: 'z. B.',
        marker_required_fields: 'Required fields',
    },
    field_labels: {
        gender: 'Salutation',
        male: 'Mr.',
        female: 'Mrs.',
        first_name: 'Firstname',
        last_name: 'Lastname',
        country: 'Country',
        street: 'Street',
        streetNumber: 'Street Number',
        postal_code: 'Postal code',
        city: 'City',
        company: 'Company',
        customer_id: 'Customer No.',
        email: 'Email',
        telephone: 'Phone',
        date_of_birth: 'Date of Birth',
        new_password: 'New password',
        new_password_confirm: 'Confirm new password',
        newsletter_checkin: 'Yes, I want to receive the Sport Bittl newsletter',
        newsletter_header: 'Register here for our newsletter, and be the first to receive our latest special offers!',
        product_id: 'Product Id',
    },
    buttons: {
        'cancel': 'Cancel',
        'edit': 'Edit',
        'save': 'Save',
        'delete': 'Delete',
        'close': 'Close',
        'add_to_cart': 'Add to cart',
        'remove_product': 'remove',
        'to_checkout': 'Checkout',
        'add': 'Add',
    },
    common: {
        'rrp': 'RRP',
        'instead': 'instead of'
    },
    prices: {
        discount_percentage: 'Save {percentage}%',
        discount_percentage_set: 'Save {percentage}% in a set',
        loyalty_percentage: '{percentage}% Loyalty Discount'
    },
    components: {
        loyalty_card_teaser: {
            'headline_customercardteaser': 'Sport Bittl PRO - Your exclusive loyalty program',
            'txt_customercardteaser': 'Register now and save up to 15% from your 1st purchase!',
            'sum': 'TOTAL',
            'sum_with_card': 'Total with loyalty card',
            'button_register_now': 'Register now',
        },
        loyalty_teaser_sc: {
            'headline_customercardteaser': 'Sport Bittl PRO Loyalty Program',
            'txt_customercardteaser': 'Save up to 15% on every purchase! Just go <strong>to the checkout</strong> and save <strong>immediately</strong>!',
            'txt_you_save': 'You save additionally',
            'total': 'Total',
        },
        shopping_cart: {
			'flyout_title': 'Very good choice! <br><span class="text">Your item is ready for you in the shopping cart.</span>',
            'title': 'Your shopping cart',
            'cart': 'Shopping cart,',
            'info_cart_empty': 'Your shopping cart is empty',
            'info_voucher_in_cart': 'Vouchers and coupons can be redeemed in the shopping cart.',
            'info_no_more_stock': 'This product is unfortunately not available in sufficient quantities.',
            'info_no_solo_buyout': 'This item can not be purchased individually.',
            'info_configure_mounting': 'Please configure your mounting',
            'info_cart_invalid': 'Cart is invalid',
            'price_per_item': 'Item price',
            'price_for_set': 'Price for the set',
            'button_edit_cart': 'Checkout',
            'button_continue_shopping': 'Continue shopping',
            'button_edit_set': 'Edit set',
            'button_remove_product': 'Delete',
            'voucher_form_header': 'You have a voucher?',
            'redeem_voucher_label': 'Coupon or value voucher',
            'redeem_voucher_placeholder': 'e.g. dsjk29',
            'button_gift_redeem': 'Activate',
            'label_size': 'Size',
            'label_count': 'Count',
            'text_product_count': '{count} items',
            'item_marker_voucher': 'Voucher',
            'item_marker_optional': 'Free',
            'item_marker_dsv': 'Insurance',
			'info_savings': 'You save',
			'info_self_pickup': 'Self-Pickup: Collect your order for free at our Munich-Allach store! Select this option in the final step of checkout.',
            mounting: {
                'headline': 'Mounting',
				'headline_bike': 'Bike settings',
                'text_select_mounting': 'Please configure mounting',
                'text_select_mounting_bike': 'Please add your body weight so that we can adjust the bike optimally for you.',
                'button_configure': 'Configure mounting',
                'button_configure_bike': 'Edit bike settings',
                'button_edit': 'Edit mounting',
				'button_edit_bike': 'Edit bike settings',
                'button_select_no_mounting': 'No mounting',
                'button_select_no_mounting_bike': 'No bike settings',
                'text_no_mounting_wanted': 'You decided <strong>not</strong> to have your ski set mounted.',
                'text_no_mounting_wanted_bike': 'You decided <strong>not</strong> to have your suspension adjusted to your body weight.',
                'text_mountpoint': 'Mounting point',
                'text_shoe_length': 'Sole length',
                'text_shoe_size': 'Shoe size',
                'text_mounting_by_shoe': 'Mounting will be made on the basis of selected shoe',
                'text_rider_weight': 'Shocks setting',
            },
        },
        checkout: {
            confirmation: {
                'headline_check_order': 'Check your order data',
                'title_shipping_address': 'Delivery address',
                'title_billing_address': 'Billing address',
                'title_payment_method': 'Payment',
                'headline_order_items': 'Your items',
                'text_newsletter': 'Yes, I would like to subscribe to the Sport Bittl newsletter and not miss any more offers.',
                'text_agbs': 'Yes, I do accept the <span style="text-decoration:underline;">general terms <a href="{link_agb}" target="_blank">and conditions (AGB)</a></span> as well as the <a href="{link_privacy}" target="_blank">privacy terms (Datenschutzbestimmungen)</a> and I have been informed about my <a href="{link_revocation}" target="_blank">right of withdrawal (Widerrufsrecht)</a>',
                'button_buy_now': 'Buy now',
            }
        },
        shipping_info: {
            description: "Please note that delivery times may be delayed due to weekends or holidays and for bicycles by the shipping company.<br><br>" +
                "<strong>Saturday</strong> is not considered a <strong>business day</strong> by our shipping service providers. <a href='/en/info/faqs.html' target='_blank'>More info</a>",
			self_pickup_description: "<strong>Free Pickup On-Site!</strong> <br>" +
				"Select the 'Self-Pickup' option in the final step of the checkout process and conveniently collect your order for free at our Munich-Allach store.",
		},
        address_book: {
            'billing_address_is_shipping_address': 'Billing address is shipping address',
            'select_different_billing_address': 'Billing address is different from delivery address?',
            'header_new_address': 'New address',
            'text_new_address': 'You want to add a new address?',
            'confirm_delete': 'Are you sure?',
            'button_change_address': 'Change address',
            'button_use_address': 'Use address',
            'button_cancel': 'Cancel',
            'button_edit': 'Edit',
            'button_save': 'Save',
            'button_delete': 'Delete',
            'button_close': 'Close',
            'button_add_address': 'Add Address',
            'button_submit_address_selection': 'Submit',
        },
        signup_form: {
            'button_signup': 'Sign up now!',
        },
        newsletter_form: {
            'confirmation_headline': 'Please confirm your registration!',
            'confirmation_text': '<p>To complete the registration, please click on the link in the email we just sent to you.</p><p>Best regards <br>Your Sport Bittl Team</p>',
        },
        textarea: {
            'chars_left': 'characters left',
        },
        bike_mounting_config: {
            overlay_info_banner: 'If you have any questions, please call our experts on the <strong>Bike Hotline</strong> at +49 89 89219-193 from Monday to Friday 10:00 - 17:00.',
            overlay_headline: 'Edit bike settings',
            label_weight: 'Shocks setting',
            placeholder_weight: 'e.g.: 75kg',
            description_weight: 'The correct and suitable adjustment of the suspension fork and, if necessary, the shock is important and individual, as it depends on your body weight. \n A well-adjusted suspension fork or an optimally adjusted rear shock are essential for mountain biking; a poor setting can even lead to falls.',
        },
        ski_mounting_config: {
            overlay_headline: 'Configure ski-mounting',
            overlay_info_banner: 'If you have any questions, please call our experts on the <strong>Ski Hotline</strong> at +49 89 89219-193 from Monday to Friday 10:00 - 17:00.',
            shoe_is_set: 'Mounting will be made on the basis of selected shoe',
            montage_line_mid: 'Ski-centre',
            montage_line_manufacturer: 'Producer recommendation',
            montage_line_choice: 'Your Choice',
            montage_pull_subtext: 'If you choose "I need advice" our mounting team will contact you',
            montage_head: 'Mounting',
            // montage_sohle: 'Sole length (mm)',
            mount_point: 'Mounting point',
            montage_no: 'No mounting',
            montage_sole: 'Sole length in mm',
            montage_shoesize: 'Shoe size',
            montage_sole_text: 'The sole length corresponds to the measured length of the ski boot sole in mm! (see pict. 1) ',
            button_save_config: 'save',
            cc_su_us_man: 'US-Man',
            cc_su_us_woman: 'US-Woman',
            montage_choose: 'Would you like a mounting?',
            montage_choose_yes: 'Yes',
            montage_choose_no: 'No',
            montage_choose_choose: 'choose',
            messages: {
                select_sole: "Please enter your sole length and click \"save\".",
                select_shoesize: "Please enter your shoe size and click \"save\".",
                adjusted_sole: "Your sole length was adjusted.",
                adjusted_shoesize: "Your shoe size was adjusted",
                no_return_for_mounted_ski: '<strong>Note</strong>:<br> Skis that are mounted and adjusted to your specifications are not returnable. You can find more detailed info in our <a href="/en/info/faqs.html#re0">FAQ</a> or <a href="/en/general-terms-and-conditions:_:3.html">Terms and Conditions</a>.'
            }
        },
        wishlist: {
            wishlist: 'Wishlist',
            wishlist_login_banner: '<strong>Info:</strong> To save your items permanently and use all features of the wishlist: <a href="/login.php">Login</a> or <a href="/login.php#form-register">Register now</a>.',
            wishlist_empty: 'Your wishlist is empty.',
            select_size_first: 'Please choose size first',
            select_size_in_setconfig: 'Select sizes in the set configurator',
            save_to_wishlist: 'Save to wishlist',
            save_to_wishlist_short: 'Wishlist',
            saved_on_wishlist: 'Saved on wishlist',
            saved_on_wishlist_long: 'The item was successfully saved on the wishlist',
            item_sold_out: 'Sold out',
            item_sold_out_set: 'Please check and finish set',
            item_sold_out_size: 'Size currently not available',
            finalize_set: 'Complete Set',
            show_sizes: 'Show sizes',
        },
        product_sizes: {
            label: "Select size",
            // label_no_selection: "Please choose size first",
            label_no_selection: "Select size",
            loading: 'loading …',
            no_store_stock: 'Only online available',
            text_store_availability_explanation_no_size: 'Please select a size above. For this size you can find here the store availability, which is updated hourly.',
            text_store_availability_explanation_with_size: 'The store availability of the product in the selected size is updated several times an hour.'
        },
		product_comparison: {
			listing_toggle: 'compare product',
			message_comparison_full_head: 'Product comparison',
			message_comparison_full: 'Your product comparison list is full. Please remove one product to add a new one.',
			go_to_comparison: 'Go to comparison',
		}
    }
};

module.exports = btlang;

// use like
//btlang.messages.error.invalidEmail
