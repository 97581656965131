"use strict";

import {bindToggleWishlistTrigger, collectWishlistLinks} from "./interaction";
import {getStoredWishlist, mergeWishlists, resetWishlist, storeWishlist} from "./storage";
import {updateWishlistCount} from "./interaction/header";
import {walkButtons} from "./interaction/buttons";
import wishlistApi from '../../api/wishlist.js';

export function initWishlist () {

    console.log("init wishlist");

    let userData = window.Bittl.UserData;

    if (!userData) {
        console.warn('userdata missing');
        return false;
    }

    console.log(userData);

    let protoWishlist = getStoredWishlist();

    if(!userData.isLoggedIn && protoWishlist.isLoggedIn) {
        resetWishlist();
    } else if((userData.isLoggedIn && userData.event === "login") || (userData.isLoggedIn && !protoWishlist.isLoggedIn)) {
        wishlistApi.queryWishlist().then(wishlistdata => {

            let newWishlist = null;
            if(protoWishlist.wishlist.length > 0) {
                newWishlist = mergeWishlists(wishlistdata.data, protoWishlist);
                newWishlist.isLoggedIn = true;
                newWishlist.user_identifier = userData.userIdent;

                protoWishlist = newWishlist;
                storeWishlist(protoWishlist, true);

            } else {
                newWishlist = wishlistdata.data;
                newWishlist.isLoggedIn = true;
                newWishlist.user_identifier = userData.userIdent;

                protoWishlist = newWishlist;
                storeWishlist(protoWishlist, false);
            }

        })
        // merge wishlist
    } else if (!userData.isLoggedIn && userData.event === "logout") {
        resetWishlist();
    } else {
        let wishlist_count = protoWishlist.wishlist.length;
        updateWishlistCount(wishlist_count);
    }
    initWishlistInteraction();
}

export function initWishlistInteraction () {
    window.sbwl = initWishlistMethods();

    bindToggleWishlistTrigger();
    collectWishlistLinks();
    walkButtons();
}

export function initWishlistMethods() {
    return {
        showWishlist() {
            console.log(getStoredWishlist())
        }
    }
}


function bindHeaderLink() {

    let link = document.querySelector('.js-header-wishlist-link');
    if (!link) {
        return false;
    }

    link.addEventListener('click', e => {
        e.preventDefault();

        window.sbwl.showWishlist();
    })
}










