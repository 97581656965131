function getLocalStorage (sKey) {
    if (!sKey) {
        return null;
    }

    let value = localStorage.getItem(sKey)
    try {
        value = JSON.parse(value);
    } catch (e) {
        //value is not Json
    }

    return value;
}

function setLocalStorage(sKey, sValue) {
    let sValueStr = null;
    if(typeof sValue === "object") {
        sValueStr = JSON.stringify(sValue);
    } else {
        sValueStr = sValue
    }

    localStorage.setItem(sKey, sValueStr);
    return true;
}

function hasLocalStorage(sKey) {
    return !! getLocalStorage(sKey);
}

function removeLocalStorage(sKey) {
    if (!hasLocalStorage(sKey)) {
        return false;
    }
    localStorage.removeItem(sKey);
    return true;
}

export default {
    get: getLocalStorage,
    set: setLocalStorage,
    has: hasLocalStorage,
    remove: removeLocalStorage,
}
