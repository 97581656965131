import {lang, isMobile, getResponsiveTag, debounce, addClass, removeClass} from './components/helpers.js'
import 'es6-promise/auto';

require('array.prototype.find').shim();
require('smoothscroll-polyfill').polyfill();

import polyfills from "./lib/polyfills";

import initVbcn from "./libs/vbcn";
import vbcnOptions from "./config/consent-settings";

import {initProductNav} from "./components/product-nav"
import initNotifications from './components/notifications';
import initDebugger from './components/debugger'
import initAnalyticsTracking from './components/analytics';
import bindArrowToTop from './components/arrow-to-top';
import bindLangSwitchFlyout from "./components/langswitch";
import bindScrollLinks from './components/scroll-links';
import scrollToAnchor from './components/scroll-to-anchor';

import {initWishlist} from "./components/wishlist";
import {handleFindoInit} from "./components/findologic-handler";

import {initImageSlideshows} from './components/image-slideshows';
import {initTeaserSlideshows} from './components/teaser-slideshows';
import {initProductSliders} from "./components/product-slider";
import {initAccordions} from "./components/accordions";
import {initBaseInfoForm} from "./components/common/baseinfo-form";
import {initTables} from "./components/tables";
import {initSignupForm} from "./components/common/init-signup-form";
import {bindPrintLinks} from "./components/common/print";
import {initProductComparisonGlobal} from "./components/product-comparison";

function initTrueGlobal() {
	console.log("true global");
	window.vbcn = initVbcn(vbcnOptions);


	initProductNav();
	initNotifications();
	initDebugger();
	initAnalyticsTracking();
	bindArrowToTop();
	bindLangSwitchFlyout();
	scrollToAnchor();
	bindScrollLinks();

	initWishlist();
	handleFindoInit();

	initImageSlideshows();
	initTeaserSlideshows();
	initProductSliders();
	initAccordions();
	initBaseInfoForm();
	initSignupForm();
	initTables();
	bindPrintLinks();

	initProductComparisonGlobal();


	scrollHandlers();
	window.addEventListener('scroll', scrollHandlers, {passive: true});
}

const scrollHandlers = debounce(function (event) {
	if (window.scrollY > 0) {
		addClass(document.querySelector('body'), 'is-scrolled');

		if (window.scrollY > 500) {
			addClass(document.querySelector('body'), 'is-scrolled-some');
		} else {
			removeClass(document.querySelector('body'), 'is-scrolled-some');
		}
	} else {
		removeClass(document.querySelector('body'), 'is-scrolled');
	}
}, 10);


export default function () {
	polyfills();
	initTrueGlobal();
}
