const vbcnOptions = {
    cookie: {
        // cookieLifeTime: 12, // * lifeTimeUnit
        // lifeTimeUnit: 'month', // month, day, session
    },
    notice: {
        ignoredPages: [
            '/de/impressum:_:4.html',
            '/en/imprint:_:4.html',
            '/de/datenschutzerklaerung:_:2.html',
            '/en/privacy-policy:_:2.html'
        ],
        ignoredHashes: [
            '#hide-my-stuff',
        ],
        backdropColor: '#474747',
        declineMode: 30,
        hasCustomDeclineButton: true,
        hasCustomCustomizeButton: false,
        autoEnableMandatory: true,
        preEnableMandatory: true,
        useConfigurationExplanation: true,
    },
    texts: {
        "de": {
            initialHeadline: 'Unser Herz schlägt für Sport – und Dein Shopping Erlebnis',
            customizeHeadline: 'Deine Datenschutz-Einstellungen',
            explanationText:
                "Sport Bittl verwendet Cookies und Tracking Technologien. Damit können wir Dein Einkaufserlebnis bei uns verbessern und funktional so gestalten, dass Du alle für Dich relevanten Produkte und Inhalte gut findest. " +
                "Die Nutzung von Tracking Technologien hilft uns dabei, dass unser Online-Shop zuverlässig und sicher läuft. Außerdem können wir unser Angebot für Dich damit stetig optimieren. <br><br>" +
                "Damit das funktioniert, klicke „Akzeptieren“ und Du stimmst der Verwendung von Cookies und der Weitergabe an Drittanbieter zu. " +
                "Wenn Du nicht einverstanden bist, dann kannst Du <a href='#' class='js-vbcn-decline js-analytics-track' data-cat='consent' data-action='decline' data-label='decline consent'>hier ablehnen</a>. In diesem Fall stimmst Du nur der Verwendung von funktional notwendigen Cookies und Tracking Technologien zu. " +
                "In Deinen <a href='#' class='js-vbcn-customize js-analytics-track' data-cat='consent' data-action='customize' data-label='customize consent'>Einstellungen</a> im Footer kannst Du diese jederzeit einsehen und bearbeiten. " +
                "Mehr Infos findest Du in der <a href='/de/datenschutzerklaerung:_:2.html'>Datenschutzerklärung</a>.",
            configuration_explanation_headline: false,
            configuration_explanation_text: false,
            accept_preset_ButtonText: 'Akzeptieren',
            declineButtonText: 'Ablehnen ',
            accept_all_ButtonText: 'Alle Cookies akzeptieren',
            decline_all_ButtonText: 'Alle ablehnen',
            customizeButtonText: 'Einstellungen',
            cancel_customizeButtonText: 'Zurück',
            saveButtonText: 'Speichern',
            closeButtonText: '×'
        },
        "en": {
            initialHeadline: 'Our heart beats for Sports – and your shopping experience',
            customizeHeadline: 'Your privacy settings',
            explanationText:
                "Sport Bittl uses cookies and tracking technologies. This allows us to design your shopping experience in such a way that you find all relevant products and contents. " +
                "Tracking technologies help us to keep our site secure, to ensure that our online shop runs reliably and that it constantly optimizes our product offer. <br><br>" +
                "For this purpose, click \"Accept\" and you agree to the use of cookies and the transfer to third parties. " +
                "If you don't agree, you can <a href='#' class='js-vbcn-decline js-analytics-track' data-cat='consent' data-action='decline' data-label='decline consent'>refuse here</a>. In this case, you only agree to use necessary and functional cookies and tracking technologies. " +
                "In your <a href='#' class='js-vbcn-customize js-analytics-track' data-cat='consent' data-action='customize' data-label='customize consent'>privacy settings</a> in the footer you can view and adjust them at any time. " +
                "For more information, please read our <a href='/en/privacy-policy:_:2.html'>privacy policy</a>.",
            configuration_explanation_headline: false,
            configuration_explanation_text: false,
            accept_preset_ButtonText: 'Accept',
            declineButtonText: 'Decline',
            accept_all_ButtonText: 'Accept all',
            decline_all_ButtonText: 'Decline all',
            customizeButtonText: 'Customize',
            cancel_customizeButtonText: 'Go back',
            saveButtonText: 'Save',
            closeButtonText: '×'
        }
    },
    sets: {
        "de": [
            {
                id: 'mandatory',
                name: 'Notwendig & Funktional',
                description: 'Die durch diese Services gesammelten Daten werden gebraucht, um die technische Funktion und Design unserer Website zu gewährleisten, Dir <strong>grundlegende Einkaufs-Funktionen</strong> für Deine Bestellung bereitzustellen, das <strong>Einkaufen bei uns sicher zu machen</strong> und um <strong>Betrug zu verhindern</strong>. Durch das Aktivieren werden personenbezogene Daten an Zahlungsdienstleister wie Paypal, Captcha Dienste sowie Webshop-Suchfunktionsanbieter übertragen.',
                mandatory: true,
                mandatory_text: "Diese Cookies sind notwendig, um Deine Einstellungen zu speichern und die Funktionalität des Shops zu gewährleisten."
            }, {
                id: 'marketing',
                name: 'Personalisierung',
                description: 'Diese Cookies und Technologien sind notwendig, um durch Statistiken und geschäftsrelevante Analysen das <strong>optimale Einkaufserlebnis für Dich</strong> zu ermöglichen. So können wir basierend auf Deinen Einkaufsgewohnheiten passende Inhalte zeigen. Durch das Aktivieren können personenbezogene Daten an Google Analytics, Facebook, Google Ads, Bing Ads, Idealo, trbo, RECOVA und Google Tag Manager übertragen werden.'
			}, {
				id: 'trust',
				name: 'Trusted Shops und authorized.by',
				description: 'Wir setzen Dienste von Trusted Shops und authorized.by ein um Den Einkauf so sicher und zuverlässig zu gestalten wie möglich.'
            }, {
                id: 'media',
                name: 'Medien',
                description: 'YouTube- und Vimeo Videos geben Dir <strong>weiterführende Informationen zu unseren Produkten</strong>. Diese Skripte und Cookies sind notwendig, um die Videos abzuspielen. Durch das Aktivieren können personenbezogene Daten an YouTube / Vimeo übertragen werden.'
            }, {
                id: 'loadbee',
                name: 'Loadbee & DemoUp Clipster',
                description: 'Die Loadbee und DemoUp Cliplister Integration geben Dir die Möglichkeit die direkte <strong>Hersteller-Information von einem Produkt</strong> zu laden. So kannst Du Dich umfangreich informieren. Diese Skripte und Cookies sind notwendig, um die Information abzurufen. Durch das Aktivieren können personenbezogene Daten an Loadbee übertragen werden.'
            }, {
                id: 'smartfit',
                name: 'Smartfit',
                description: 'Die Smartfit Integration gibt Dir die Möglichkeit die direkte <strong>Hersteller-Information zu einem Produkt</strong> zu laden. So kannst Du Dich umfangreich informieren. Diese Skripte und Cookies sind notwendig, um die Information abzurufen. Durch das Aktivieren können personenbezogene Daten an Loadbee übertragen werden.'
            }, {
                id: 'bike-leasing-calculator',
                name: 'Bike Leasing Kalkulator',
                description: 'Auf unseren Bike Leasing Landing Pages findest Du einen integrierten <strong>Bike Leasing Beispielrechner</strong>. Um zu entscheiden, ob das gewählte Fahrrad das Richtige für Dich ist, liefert er Dir relevante Informationen. Diese Skripte und Cookies sind notwendig, um den Kalkulator zu verwenden. Durch das Aktivieren können personenbezogene Daten an die Bike Leasing Anbieter übertragen werden.'
            }, {
                id: 'shore',
                name: 'Shore',
                description: 'Damit Du online Termine für Serviceleistungen in unseren Filialen buchen kannst, für bspw. Radservice oder Skischuhberatung, findest Du das <strong>Terminbuchungstool Shore</strong> auf unserer Website. Durch das aktivieren dieser Integration können personenbezogene Daten an Shore übertragen werden.'
            }
        ],
        "en": [
            {
                id: 'mandatory',
                name: 'Necessary & Functional',
                description: 'The data collected by these services is used to ensure the technical performance and design of our website, to provide you with <strong>basic shopping functions</strong> while placing your order, as well as <strong>for security and fraud prevention purposes</strong>. By activating, personal data will be transferred to payment service providers e.g. paypal, captcha providers as well as webshop search providers.',
                mandatory: true,
                mandatory_text: "These cookies are necessary to save your settings and to ensure the functionality of the store."
            }, {
                id: 'marketing',
                name: 'Personalization',
                description: 'These scripts, cookies and tracking technologies are necessary to <strong>provide your optimal shopping experience</strong> through business-relevant analyses and statistics. Thus, we are able to show you personalized content, e.g. based on <strong>your shopping habbits</strong>. By activating, personal data can be transferred to Google Analytics, Facebook, Google Ads, Bing Ads, Idealo, trbo, RECOVA and Google Tag Manager.'
			}, {
				id: 'trust',
				name: 'Trusted Shops and authorized.by',
				description: 'We use the services of Trusted Shops and authorized.by to make your purchase as safe and reliable as possible.'
            }, {
                id: 'media',
                name: 'Media',
                description: 'YouTube and Vimeo videos give you <strong>more information about our products</strong>. These scripts and cookies are necessary to play the videos. By activating, personal data can be transferred to YouTube / Vimeo.'
            }, {
                id: 'loadbee',
                name: 'Loadbee & DemoUp Clipster',
                description: 'The Loadbee & Cliplister integrations give you the possibility to <strong>load direct manufacturer information of a product</strong>. Here you can find relevant comprehensive information. These scripts and cookies are necessary to retrieve the information. Activation allows personal data to be transferred to Loadbee.'
			}, {
				id: 'smartfit',
				name: 'Smartfit',
				description: 'Die Smartfit Integration gibt Dir die Möglichkeit die direkte <strong>Hersteller-Information zu einem Produkt</strong> zu laden. So kannst Du dich umfangreich informieren. Diese Skripte und Cookies sind notwendig, um die Information abzurufen. Durch das Aktivieren können personenbezogene Daten an Loadbee übertragen werden.'
            }, {
                id: 'bike-leasing-calculator',
                name: 'Bike Leasing Calculator',
                description: 'On our bike leasing landing pages you will find an integrated <strong>bike leasing calculator</strong>. It provides you with relevant information to help you decide if the bike you have chosen is right for you. These scripts and cookies are necessary to use the calculator. By activating them, personal data can be transferred to the bike leasing providers.'
            }, {
                id: 'shore',
                name: 'Shore',
                description: 'So that you can book appointments online for services in our stores, for example for bike service or ski boot advice, you will find the <strong>appointment booking tool Shore</strong> on our website. By activating this integration, personal data can be transferred to Shore.'
			}
		]
    }
}

export default vbcnOptions;
