import axios from 'axios';
import {handleError} from "./_shared";

const settings = {
    baseUrl: '/api/comparison.php',
}

export default {

	addProduct(productsId, templateId) {
		return new Promise((resolve, reject) =>

			axios({
				method: 'get',
				url: settings.baseUrl,
				params: {
					action: 'addCompare',
					productsId,
					templateId
				}
			})
				.then(response => resolve(response.data))
				.catch(function (error) {
					handleError(error, reject);
				})
		)
	},

	removeProduct(productsId, templateId) {
		return new Promise((resolve, reject) =>

			axios({
				method: 'get',
				url: settings.baseUrl,
				params: {
					action: 'removeCompare',
					productsId,
					templateId
				}
			})
				.then(response => resolve(response.data))
				.catch(function (error) {
					handleError(error, reject);
				})
		)
	},

	getProductIds() {
		return new Promise((resolve, reject) =>

			axios({
				method: 'get',
				url: settings.baseUrl,
				params: {
					action: 'getProductIds',
				}
			})
				.then(response => resolve(response.data))
				.catch(function (error) {
					handleError(error, reject);
				})
		)
	},
}
