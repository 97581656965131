<template>
    <div id="signup-form" class="signup-form" ref="signupForm">

        <div class="errors" v-if="errors.length > 0">
            <div class="notification is-warning">
                {{ $t("messages.errors.formError") }}
                <span v-for="(error, index) in errors"><span v-if="index !== 0">, </span>{{ error }}</span>
            </div>
        </div>

        <div class="form__fieldgroup-row">

            <div class="signup-form__fieldgroup form__fieldgroup is-half">
                <label class="signup-form__label form__label" for="signup-form__gender">
                    {{ $t("field_labels.gender") }} <span class="marker--required">*</span>
                </label>
                <select name="gender"
                        id="signup-form__gender"
                        class="signup-form__input form__select form__input select-input text-input u-full-width"
                        v-model="customerData.gender"
                        required >
                    <option value=""> </option>
                    <option value="f">{{ $t("field_labels.female") }}</option>
                    <option value="m">{{ $t("field_labels.male") }}</option>
                </select>

            </div>
        </div>

        <div class="form__fieldgroup-row">


            <div class="signup-form__fieldgroup form__fieldgroup">
                <label class="signup-form__label form__label" for="signup-form__first-name">
                    {{ $t("field_labels.first_name") }} <span class="marker--required">*</span>
                </label>
                <input type="text"
                       :placeholder='$t("field_labels.first_name")'
                       class="signup-form__input form__input text-input u-full-width"
                       id="signup-form__first-name"
                       name="firstname"
                       required
                       v-model="customerData.firstName"
                       title="title"/>
            </div>

            <div class="signup-form__fieldgroup form__fieldgroup">
                <label class="signup-form__label form__label" for="signup-form__last-name">
                    {{ $t("field_labels.last_name") }}  <span class="marker--required">*</span>
                </label>
                <input type="text"
                       :placeholder='$t("field_labels.last_name")'
                       class="signup-form__input form__input text-input u-full-width"
                       id="signup-form__last-name"
                       name="Address lastname"
                       v-model="customerData.lastName"
                       required />
            </div>
        </div>

        <div class="form__fieldgroup-row">
            <div class="signup-form__fieldgroup form__fieldgroup is-half">
                <label class="signup-form__label form__label" for="signup-form__date_of_birth">
                    {{ $t("field_labels.date_of_birth") }}
                </label>
                <input type="date"
                       :placeholder='$t("field_labels.date_of_birth")'
                       class="signup-form__input form__input text-input u-full-width"
                       id="signup-form__date_of_birth"
                       name="Address date_of_birth"
                       v-model="customerData.date_of_birth"
                />
            </div>
        </div>


        <div class="form__fieldgroup-row">

            <div class="signup-form__fieldgroup form__fieldgroup">
                <label class="signup-form__label form__label" for="signup-form__street">
                    {{ $t("field_labels.street") }}  <span class="marker--required">*</span>
                </label>
                <input type="text"
                       :placeholder='$t("field_labels.street")'
                       class="signup-form__input form__input text-input u-full-width"
                       id="signup-form__street"
                       name="Address streetaddress"
                       maxlength="50"
                       v-model="customerData.street"
                       required />
            </div>

            <div class="signup-form__fieldgroup form__fieldgroup">
                <label class="signup-form__label form__label" for="signup-form__street-number">
                    {{ $t("field_labels.streetNumber") }}  <span class="marker--required">*</span>
                </label>
                <input type="text"
                       :placeholder='$t("field_labels.streetNumber")'
                       class="signup-form__input form__input text-input u-full-width"
                       id="signup-form__street-number"
                       name="Address street_number"
                       v-model="customerData.streetNumber"
                       required />
            </div>

        </div>


        <div class="form__fieldgroup-row">
            <div class="signup-form__fieldgroup form__fieldgroup is-half">
                <label class="signup-form__label form__label" for="signup-form__country">
                    {{ $t("field_labels.country") }}  <span class="marker--required">*</span>
                </label>
                <select type="text"
                        class="signup-form__input form__select form__input select-input text-input u-full-width"
                        id="signup-form__country"
                        name="Address country_id"
                        required
                        v-model="selectedCountryID">
                    <option value="" v-if="!selectedCountryID || selectedCountryID === 0">---</option>
                    <option v-for="country in countryOptions" :value="country.id" >{{ country.name_de }}</option>
                </select>
            </div>
        </div>

        <div class="form__fieldgroup-row">

            <div class="signup-form__fieldgroup form__fieldgroup">
                <label class="signup-form__label form__label" for="signup-form__zip">
                    {{ $t("field_labels.postal_code") }}  ({{ $t("helpers.e_g") }} : "{{ selectedCountry.format_display}}")
                    <span class="marker--required">*</span>
                </label>

                <input type="text"
                       :placeholder='$t("field_labels.postal_code")'
                       class="signup-form__input form__input text-input u-full-width"
                       id="signup-form__zip"
                       name="Address postcode"
                       :pattern="preparePattern(selectedCountry.zip_regex)"
                       :maxlength="selectedCountry.format_display ? selectedCountry.format_display.length : false"
                       v-model="customerData.zip"
                       required />
            </div>

            <div class="signup-form__fieldgroup form__fieldgroup">
                <label class="signup-form__label form__label" for="signup-form__city">
                    {{ $t("field_labels.city") }}  <span class="marker--required">*</span>
                </label>
                <input type="text"
                       :placeholder='$t("field_labels.city")'
                       class="signup-form__input form__input text-input u-full-width"
                       id="signup-form__city"
                       name="Address city"
                       v-model="customerData.city"
                       required/>
            </div>

        </div>


        <div class="form__fieldgroup-row">
            <div class="signup-form__fieldgroup form__fieldgroup">
                <label class="signup-form__label form__label" for="signup-form__email">
                    {{ $t("field_labels.email") }}  <span class="marker--required">*</span>
                </label>
                <input type="email"
                       :placeholder='$t("field_labels.email")'
                       class="signup-form__input form__input text-input u-full-width"
                       id="signup-form__email"
                       name="email"
                       v-model="customerData.email"
                       required/>
            </div>

                <div class="signup-form__fieldgroup form__fieldgroup">
                    <label class="signup-form__label form__label" for="signup-form__tel">
                        {{ $t("field_labels.telephone") }}  <span class="marker--required">*</span>
                    </label>
                    <input type="text"
                           :placeholder='$t("field_labels.telephone")'
                           class="signup-form__input form__input text-input u-full-width"
                           id="signup-form__tel"
                           v-model="customerData.telephone"
                           maxlength="15"
                           name="Address telephone"
                           required/>
                </div>
        </div>


        <div class="form__fieldgroup-row">


            <div class="signup-form__fieldgroup form__fieldgroup">
                <label class="signup-form__label form__label" for="signup-form__new_password">
                    {{ $t("field_labels.new_password") }}
                    <span class="marker--required">*</span>
                </label>
                <input type="password"
                       :placeholder='$t("field_labels.new_password")'
                       class="signup-form__input form__input text-input u-full-width"
                       id="signup-form__new_password"
                       v-model="customerData.new_password"
                       name="new_password"
                       required />
            </div>

            <div class="signup-form__fieldgroup form__fieldgroup">
                <label class="signup-form__label form__label" for="signup-form__new_password_confirm">
                    {{ $t("field_labels.new_password_confirm") }}
                    <span class="marker--required">*</span>
                </label>
                <input type="password"
                       :placeholder='$t("field_labels.new_password_confirm")'
                       class="signup-form__input form__input text-input u-full-width"
                       id="signup-form__new_password_confirm"
                       v-model="customerData.new_password_confirm"
                       name="new_password_confirm"
                       required />
            </div>
        </div>

        <div class="form__fieldgroup-row">
            <div class="signup-form__fieldgroup form__fieldgroup">
                <span class=" form__sub-fieldgroup u-no-margin-bottom">

                <label class="baseinfo-form__label form__label is-inline" for="newsletter_checkin">
                    <input class="baseinfo-form__input form__radio checkbox-input"
                           type="checkbox"
                           id="newsletter_checkin"
                           name="newsletter_checkin"
                           v-model="customerData.newsletter_checkin"
                           value="off"/>
                    <span class="form__label__title">
                        {{ $t("field_labels.newsletter_checkin") }}
                    </span>
                    <span class="form__label__description">
                        {{ $t("field_labels.newsletter_header") }}
                    </span>

                </label>
                </span>

            </div>
        </div>

        <div class="signup-form__fieldgroup form__fieldgroup u-no-margin-bottom">
            <button @click.prevent="signup()" type="button" class="button main-action-button " >{{ $t("components.signup_form.button_signup") }}</button>
        </div>


    </div>
</template>

<script>
import axios from "axios";
import {lang, validateEmail} from "../helpers";

export default {
    name: 'SignupForm',
    props: {
    },

    data() {
        return {
            customerData: {},
            selectedCountryID: null,
            countryOptions: [],
            errors: [],
            successful_signup: false,
        }
    },

    methods: {
        preparePattern(zip_regex) {
			if(!zip_regex) {
                return ".*";
            }
            return zip_regex.substring(0, zip_regex.length - 1).substring(1);
        },
        prepareMaxLength(format_display) {
            return format_display.length
        },

        cancelEdit() {
            // reset to cached version
            for (const field in this.addressDataCache) {
                if(this.addressDataCache.hasOwnProperty(field) && this.customerData.hasOwnProperty(field) ) {
                    this.addressData[field] = this.addressDataCache[field];
                }
            }

            this.customerData.editMode = false;
        },

        signup() {
            if(this.checkCustomerDataValid()) {
                let data = JSON.parse(JSON.stringify(this.customerData));

                this.$store.dispatch('signupForm/signup', data).then((response) => {
                    this.successful_signup = true;
                    this.errors = [];
                    if(response.hasOwnProperty('redirect')) {
                        this.redirectToSuccessPage(response.redirect);
                    }
                }).catch(error => {
                    if(error.hasOwnProperty('errors')) {
                        this.errors = error.errors;
                    } else {
                        this.errors.push(error);
                    }
                    this.$refs.signupForm.scrollIntoView({ behavior: 'smooth' });
                })
            }

        },

        redirectToSuccessPage(pageurl) {
            console.log(pageurl);
            window.location = pageurl;
        },

        checkCustomerDataValid() {
            let isValid = true;
            let errors = [];

            if(!this.customerData.gender || this.customerData.gender === "") {
                errors.push(this.$t("form_validation.missing_gender_fieldname"));
                isValid = false;
            }

            if(!this.customerData.firstName || this.customerData.firstName === "") {
                errors.push(this.$t("form_validation.missing_firstname_fieldname"));
                isValid = false;
            }

            if(!this.customerData.lastName || this.customerData.lastName === "") {
                errors.push(this.$t("form_validation.missing_lastname_fieldname"));
                isValid = false;
            }

            if(!this.customerData.street || this.customerData.street === "") {
                errors.push(this.$t("form_validation.missing_street_fieldname"));
                isValid = false;
            }

            if(!this.customerData.streetNumber || this.customerData.streetNumber === "") {
                errors.push(this.$t("form_validation.missing_streetnumber_fieldname"));
                isValid = false;
            }

            if(!this.customerData.zip || this.customerData.zip === "") {
                errors.push(this.$t("form_validation.missing_postalcode_fieldname"));
                isValid = false;
            }

            if(this.customerData.zip) {
                const regex = this.selectedCountry.zip_regex;
				if (regex) {
					const found = this.customerData.zip.match(regex);

					if (!found) {
						errors.push(this.$t("form_validation.invalid_postalcode"));
						isValid = false;
					}
				}
            }

            if(!this.customerData.city || this.customerData.city === "") {
                errors.push(this.$t("form_validation.missing_city_fieldname"));
                isValid = false;
            }

            if(!this.customerData.telephone || this.customerData.telephone === "") {
                errors.push(this.$t("form_validation.missing_telephone_fieldname"));
                isValid = false;
            }

            if(!this.customerData.email || this.customerData.email === "") {
                errors.push(this.$t("form_validation.missing_email_fieldname"));
                isValid = false;
            } else if(!validateEmail(this.customerData.email)) {
                errors.push(this.$t("form_validation.wrong_email"));
                isValid = false;
            }

            if(!this.customerData.new_password || this.customerData.new_password === "") {
                errors.push(this.$t("form_validation.missing_password_fieldname"));
                isValid = false;
            } else if(!this.customerData.new_password_confirm || this.customerData.new_password_confirm === "") {
                errors.push(this.$t("form_validation.missing_password_confirm_fieldname"));
                isValid = false;
            } else if (this.customerData.new_password !== this.customerData.new_password_confirm) {
                errors.push(this.$t("form_validation.password_no_match"));
                isValid = false;
            }

            if(!isValid) {
                this.errors = errors;
                this.$refs.signupForm.scrollIntoView({ behavior: 'smooth' });
                return false;
            } else {
                return true;
            }
        }

    },
    created() {
        axios.get('/lang/shipping_countries.json')
            .then(data => {
                this.countryOptions = data.data
            })

        // if(this.addressData.countryId) {
            this.selectedCountryID = 81;
        // }

        // this.addressDataCache = JSON.parse(JSON.stringify(this.addressData));

        // this.customerData = this.addressData;
    },
    watch: {
        selectedCountry() {
            this.customerData.countryId = this.selectedCountry.id;
            this.customerData.countriesName = lang === 'de' ? this.selectedCountry.name_de : this.selectedCountry.name_en;
            this.customerData.countriesIsoCode = this.selectedCountry.iso;
        }
    },
    computed: {
        selectedCountry() {
            if(this.countryOptions.length <= 0) {
                return false;
            }
            return this.countryOptions.find(option => option.id === parseInt(this.selectedCountryID))
        },
    }
}
</script>
