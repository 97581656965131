import {getStoredWishlist, mergeWishlists, storeWishlist, userData} from "../storage";
import {markProductButtonsActive, markProductButtonsInactive} from "./buttons";
import wishlistApi from "../../../api/wishlist";

export function productIsInWishlist(configId, wishlist = null) {
    if(!wishlist) {
        wishlist = getStoredWishlist();
    }
    return wishlist.wishlist.some(product => product.config_id === configId)
}

export async function productIsInDbWishlist(configId) {
    let dbWishlist = await wishlistApi.queryWishlist();
    return productIsInWishlist(configId, dbWishlist.data);
}

export function toggleProduct (configId, type = 'product') {
    let found = productIsInWishlist(configId);
    if (found) {
        removeProduct(configId);
    } else {
        addProduct(configId, type);
    }
}

export function addProduct (configId, type) {

    let wishlist = getStoredWishlist();
    if (!wishlist) {
        console.error('wishlist invalid')
        return false;
    }

    let found = productIsInWishlist(configId, wishlist);

    if (found) {
        console.warn('product already in wishlist');
        return false;
    }

    wishlist.wishlist.push({"type": type, "config_id": configId});

    console.log(wishlist);

    if(storeWishlist(wishlist)) {
        markProductButtonsActive(configId)
        return true;
    } else {
        console.error("could not store wishlist")
        return false;
    }

}

export async function removeProduct(configId) {
    let wishlist = getStoredWishlist();
    if (!wishlist) {
        return false;
    }

    let found = productIsInWishlist(configId, wishlist);
    let foundInDb = false;
    if (!found) {
        if(userData.isLoggedIn && wishlist.isLoggedIn) {
            foundInDb = await productIsInDbWishlist(configId);
            if(!foundInDb) {
                console.log("product neither in wishlist nor in db");
                return false;
            }
        } else {
            console.warn('product not in wishlist');
            return false;
        }

    }

    let index = wishlist.wishlist.findIndex(product => product.config_id === configId)

    if(index === -1 && !foundInDb) {
        console.log("product not found");
        return false;
    }

    if(index !== -1) {
        wishlist.wishlist.splice(index, 1);
    }

    console.log(wishlist);

    if(storeWishlist(wishlist)) {
        markProductButtonsInactive(configId)
        return true;
    } else {
        console.error("could not store wishlist")
        return false;
    }
}

export function replaceProduct (oldConfigId, newConfigId) {

    let wishlist = getStoredWishlist();
    if (!wishlist) {
        console.error('wishlist invalid')
        return false;
    }

    let found = productIsInWishlist(oldConfigId, wishlist);

    if (!found) {
        console.warn('product not in wishlist');
        return false;
    }

    let index = wishlist.wishlist.findIndex(product => product.config_id === oldConfigId)

    wishlist.wishlist[index].config_id = newConfigId;

    console.log(wishlist);

    if(storeWishlist(wishlist)) {
        markProductButtonsActive(newConfigId)
        return true;
    } else {
        console.error("could not store wishlist")
        return false;
    }

}
