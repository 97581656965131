import axios from 'axios';
import {handleError} from "./_shared";

const settings = {
    baseUrl: '/api/newsletter.php',
}

export default {
    signup(customerData) {

        return new Promise((resolve, reject) =>
            axios.post(settings.baseUrl,
                customerData,
                {
                    params: {
                        action: 'subscribe',
                        // customerId: address.customerId
                    }
                })
                .then(response => resolve(response.data))

				.catch(function (error) {
					handleError(error, reject);
				})
        )
    },
}
