import {addClass, removeClass, toggleClass} from "../../helpers";
import {getStoredWishlist} from "../storage";
import {productIsInWishlist} from "./product";
import {state} from "../storage/state";
import {options} from "../storage/options";

export function walkButtons () {
    let wishlist = getStoredWishlist();

    state.buttons.forEach(button => {
        let configId = button.dataset.configId;

        let found = productIsInWishlist(configId, wishlist);
        if (found) {
            markButtonActive(button)
        } else {
            markButtonInactive(button);
        }
    })
}

export function toggleButtonActiveMark(button) {
    toggleClass(button, options.classes.isInWishlist);
}

export function markButtonActive(button) {
    addClass(button, options.classes.isInWishlist);
}

export function markButtonInactive(button) {
    removeClass(button, options.classes.isInWishlist);
}

export function markProductButtonsActive(configId) {
    console.log(state);
    let buttons = state.buttons.filter(button => button.dataset.configId === configId);
    console.log(buttons);
    Array.from(buttons).forEach(button => {
        markButtonActive(button);
    })
}

export function markProductButtonsInactive(configId) {
    let buttons = state.buttons.filter(button => button.dataset.configId === configId);
    Array.from(buttons).forEach(button => {
        markButtonInactive(button);
    })
}
