import {addClass, findAncestorByClass, removeClass, toggleClass} from "../../helpers";
import {walkButtons} from "./buttons";
import {addProduct, productIsInWishlist, removeProduct, toggleProduct} from "./product";
import {state} from "../storage/state";

export function bindToggleWishlistTrigger() {

    document.addEventListener('click', (event) => {
        let target = event.target.classList.contains('js-product-wishlist-button') ? event.target : false;
        if(!target) {
            let tempTarget = findAncestorByClass(event.target, 'js-product-wishlist-button');
            target = tempTarget ? tempTarget : false
        }
        if(target) {

            let interactionType = target.dataset.wishlistInteraction;
            let configId = target.dataset.configId;
            let type = target.dataset.configType === "set" ? "set" : "product";

            if(interactionType === "add-only") {

                let found = productIsInWishlist(configId);
                if (found) {
                    event.preventDefault();
                    event.cancelBubble;
                    return false;

                    // let new_location = new URL(window.location.origin);
                    // new_location.pathname = "/wishlist.php";
                    // new_location.search = "";
                    //
                    // window.location = new_location;
                } else {
                    addProduct(configId, type);
                }
            } else {
                toggleProduct(configId, type);
            }
        }
    })
}

export function collectWishlistLinks () {
    let wishlistButtons = document.querySelectorAll('.js-product-wishlist-button');

    if (!wishlistButtons) {
        return false;
    }
    console.log("bla");

    if (wishlistButtons) {
        state.buttons = Array.from(wishlistButtons);
        walkButtons();
    }
}

