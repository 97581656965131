import axios from 'axios';
import {handleError} from "./_shared";

const settings = {
    baseUrl: '/api/wishlist.php',
}

export default {

    queryWishlistProducts(wishlist) {
        return new Promise((resolve, reject) =>

            axios.post(settings.baseUrl,
                wishlist,
                {
                    params: {
                        action: 'get_products',
                    }
                })
                .then(response => resolve(response.data))
				.catch(function (error) {
					handleError(error, reject);
				})
        )
    },

    saveWishlist(wishlist) {
        return new Promise((resolve, reject) =>

            axios.post(settings.baseUrl,
                wishlist,
                {
                    params: {
                        action: 'save',
                    }
                })
            .then(response => resolve(response.data))
			.catch(function (error) {
				handleError(error, reject);
			})
        )
    },

    queryWishlist() {
        return new Promise((resolve, reject) =>

            axios({
                method: 'get',
                url: settings.baseUrl,
                params: {
                    action: 'get'
                }
            })
            .then(response => resolve(response.data))
			.catch(function (error) {
				handleError(error, reject);
			})
        )
    },
}
