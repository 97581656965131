'use strict';

require('smoothscroll-polyfill').polyfill();

export default function () {

    let anchor = window.location.hash;

    if (anchor === "" || typeof (anchor) === "undefined") {
        return
    }

    let element = false;

    try {
        element = document.querySelector(anchor);

        console.log(element);
    } catch (error) {
        console.log('not a selectable thing');
        return;
    }

    console.log(element);

    if (element) {
        // console.log(element);
        window.scroll(0,0);

        setTimeout(function () {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },1)
    }
}
