"use strict";

import Flickity from 'flickity-imagesloaded';
import {addClass, hasClass, isElementInViewport, lang, removeClass, wrapElement} from "./helpers";

const defaults = {
    selectors: {
        slideshow: '.js-teaser-slideshow',
        slide: '.js-teaser-slide',
    },
    classes: {
        initClass: 'flky-init',
        counterContainer: 'slideshow__counter',
        counterCurrent: 'current',
        counterDivider: 'divider',
        counterTotal: 'total',

    },
    autoplaySpeed: 5000,
};

const state = {
    slideshows: []
};

let panelWrapper, flkty, nextButton, prevButton, menuButton, menu;

function initialize (parentNode = document) {
    let slideshows = document.querySelectorAll(defaults.selectors.slideshow);

    if (!slideshows) {
        return false;
    }

    if (slideshows) {
        Array.from(slideshows).forEach(slideshow => {

            if(hasClass(slideshow, defaults.classes.initClass)) {
                return;
            }


            let autoplay = slideshow.dataset['autoplay'] ? defaults.autoplaySpeed : false;
            const parentEl = slideshow.parentNode;
            const initEl = slideshow.parentNode.parentNode;

            const slides = slideshow.querySelectorAll(defaults.selectors.slide);

            let slideCount = slides.length;

            let slideShow = {
                slideshow,
                slides,
                autoplay,
                slideCount
            };

            slideShow.singleSlideshow = new Flickity( slideshow, {
                cellSelector: defaults.selectors.slide,
                wrapAround: true,
                autoPlay: autoplay,
                imagesLoaded: true,
                adaptiveHeight: true,
                on: {
                    ready: function() {
                        slideShow.pager =  slideShow.slideshow.querySelector('.flickity-page-dots');

                        if (slideCount > 5) {
                            buildPagerCounter(slideShow);
                        }
                    },
                    change: function( index ) {
                        updatePagerCounter(slideShow, index);

                    }
                }
            });


            if (autoplay) {
                let handler = onVisibilityChange(slideshow, function(isVisible) {
                    if (isVisible) {
                        slideShow.singleSlideshow.unpausePlayer();
                    } else {
                        slideShow.singleSlideshow.pausePlayer();
                    }
                });

                if (window.addEventListener) {
                    addEventListener('scroll', handler, false);
                    addEventListener('resize', handler, false);
                } else if (window.attachEvent)  {
                    attachEvent('onscroll', handler);
                    attachEvent('onresize', handler);
                }
            }

            addClass(slideshow, defaults.classes.initClass);
            addClass(initEl, defaults.classes.initClass);

            state.slideshows.push(slideShow);
        });
    }
}

function onVisibilityChange(el, callback) {
    let old_visible;
    return function () {
        let visible = isElementInViewport(el);
        if (visible !== old_visible) {
            old_visible = visible;
            if (typeof callback == 'function') {
                callback(visible);
            }
        }
    }
}

function goToPanel (index) {

    flkty.select( index );

}

function buildPagerCounter(slideShow, currentIdx) {

    let counterContainer = document.createElement('div');
    addClass(counterContainer, defaults.classes.counterContainer);

    let counterCurrent = document.createElement('span');
    addClass(counterCurrent, defaults.classes.counterCurrent);

    let counterDivider = document.createElement('span');
    addClass(counterDivider, defaults.classes.counterDivider);
    counterDivider.innerText = lang === 'de' ? ' von ' : ' of ';

    let counterTotal = document.createElement('span');
    addClass(counterTotal, defaults.classes.counterTotal);

    counterCurrent.innerText = currentIdx ? currentIdx + 1 : 1;
    counterTotal.innerText = slideShow.slideCount;

    counterContainer.append(counterCurrent, counterDivider, counterTotal );

    addClass(slideShow.pager, 'counter');

    slideShow.pager.append(counterContainer);

    slideShow.counterContainer = counterContainer;
    slideShow.counterCurrent = counterCurrent;
    slideShow.counterDivider = counterDivider;
    slideShow.counterTotal = counterTotal;
    slideShow.hasCounter = true;
}

function updatePagerCounter(slideShow, currentIdx) {
    if (!slideShow.hasCounter) {
        return;
    }

    slideShow.counterCurrent.innerText = currentIdx ? currentIdx + 1 : 1;
}

export function initTeaserSlideshows(parentNode = document) {
    initialize(parentNode);
}
