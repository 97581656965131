const btlang = {
    langCode: "de-DE",
    form_validation: {
        missing_gender: "Bitte wähle Deine Anrede",
        missing_gender_fieldname: "Anrede",
        missing_firstname: "Bitte gebe Deinen Vornamen an",
        missing_firstname_fieldname: "Vorname",
        missing_lastname: "Bitte gebe Deinen Nachnamen an",
        missing_lastname_fieldname: "Nachname",
        missing_street: "Bitte gebe eine Straße an",
        missing_street_fieldname: "Straße",
        missing_postalcode: "Bitte gebe eine Postleitzahl an",
        missing_postalcode_fieldname: "Postleitzahl",
        missing_city: "Bitte gebe Deine Stadt an",
        missing_city_fieldname: "Stadt",
        missing_telephone: "Bitte gebe Deine Telefonnummer an",
        missing_telephone_fieldname: "Telefonnummer",
        invalid_postalcode: "Die Postleitzahl ist nicht gültig",
        invalid_postalcode_short: "Postleitzahl ungültig",
        missing_streetnumber: "Bitte gebe Deine Hausnummer an",
        missing_streetnumber_fieldname: "Hausnummer",
        missing_email: "Bitte gebe Deine E-Mail an",
        missing_email_fieldname: "E-Mail",
        wrong_email: "Bitte gebe eine valide E-Mail-Adresse an",
        missing_password: "Bitte gebe ein Passwort an",
        missing_password_fieldname: "Passwort",
        missing_password_confirm: "Bitte bestätige dein Passwort",
        missing_password_confirm_fieldname: "Passwortbestätigung",
        password_no_match: "Die Passwörter müssen übereinstimmen",
        multiple_errors: "Bitte überprüfe deine Angaben",
    },
    messages: {
        errors: {
            paymentCreditCard: "Das hat leider nicht geklappt, bitte prüfe Deine Eingaben und versuche es erneut.",
            paymentWrongBrand: "Die verwendete Kreditkarte wird derzeit nicht unterstützt. Bitte wähle eine andere Kreditkarte oder Zahlungsart.",
            formError: "Bitte überprüfe Deine Angaben hier: ",

            //     checkoutAddress : "Folgende Felder m&uuml;ssen ausgef&uuml;llt werden!",
            //     invalidEmail: "Bitte geben Sie eine g&uuml;ltige Emailadresse an!",
            //     skiMountingSole: "Die Sohlenl&auml;nge muss eine dreistellige Ganzzahl sein!"
        }
    },
    headings: {
        search: {
            products: 'Produkte',
            brands: 'Marken',
            categories: 'Kategorien',
            pages: 'Seiten',
        },
        payment_methods_pay_with: 'Sicher bezahlen mit'
    },
    helpers: {
        e_g: 'z. B.',
        marker_required_fields: 'Pflichtfelder',
    },
    field_labels: {
        gender: 'Anrede',
        male: 'Herr',
        female: 'Frau',
        first_name: 'Vorname',
        last_name: 'Nachname',
        country: 'Land',
        street: 'Straße',
        streetNumber: 'Hausnummer',
        postal_code: 'Postleitzahl',
        city: 'Stadt',
        company: 'Unternehmen',
        customer_id: 'Kundennummer',
        email: 'E-Mail',
        telephone: 'Telefon',
        date_of_birth: 'Geburtsdatum',
        new_password: 'Neues Passwort',
        new_password_confirm: 'Neues Passwort bestätigen',
        newsletter_checkin: 'Ja ich will den Sport Bittl Newsletter',
        newsletter_header: 'Newsletter abonnieren und als Erster von unseren Sonderangeboten, News und Events erfahren.',
        product_id: 'Artikelnummer',
    },
    buttons: {
        'cancel': 'Abbrechen',
        'edit': 'Bearbeiten',
        'save': 'Speichern',
        'delete': 'Löschen',
        'close': 'Schließen',
        'add_to_cart': 'In den Warenkorb',
        'remove_product': 'entfernen',
        'to_checkout': 'Zur Kasse',
        'add': 'Hinzufügen',
    },
    common: {
        'rrp': 'UVP',
        'instead': 'statt',
    },
    prices: {
        discount_percentage: '{percentage}% sparen',
        discount_percentage_set: '{percentage}% im Set sparen',
        loyalty_percentage: '{percentage}% Vorteilskartenrabatt'
    },
    components: {
        loyalty_card_teaser: {
            'headline_customercardteaser': 'Sport Bittl PRO – Dein exklusives Vorteilsprogramm',
            'txt_customercardteaser': 'Jetzt registrieren und ab Deinem 1. Einkauf bis zu 15% sparen!',
            'sum': 'SUMME',
            'sum_with_card': 'Summe mit Vorteilskarte',
            'button_register_now': 'Jetzt registrieren',
        },
        loyalty_teaser_sc: {
            'headline_customercardteaser': 'Sport Bittl PRO Vorteilsprogramm',
            'txt_customercardteaser': 'Spare bis zu 15% bei jedem Einkauf! Einfach <strong class="u-text-no-wrap">zur Kasse gehen</strong> und <strong>sofort</strong> Vorteilspreis sichern!',
            'txt_you_save': 'Du sparst zusatzlich',
            'total': 'Gesamtsumme',
        },
        shopping_cart: {
			'flyout_title': 'Sehr gute Wahl! <br><span class="text">Das Produkt liegt im Warenkorb für Dich bereit.</span>',
            'title': 'Dein Warenkorb',
            'cart': 'Warenkorb,',
            'info_cart_empty': 'Dein Warenkorb ist leer',
            'info_voucher_in_cart': 'Gutschein- und Couponeinlösung im Warenkorb möglich',
            'info_no_more_stock': 'Dieser Artikel ist leider nicht in der gewünschten Menge verfügbar.',
            'info_no_solo_buyout': 'Dieser Artikel ist einzeln nicht erwerbbar.',
            'info_configure_mounting': 'Bitte wähle deine Montage aus',
            'info_cart_invalid': 'Warenkorb ungültig',
            'price_per_item': 'Einzelpreis',
            'price_for_set': 'Setpreis',
            'button_edit_cart': 'Zur Kasse',
            'button_continue_shopping': 'weiter einkaufen',
            'button_edit_set': 'Set bearbeiten',
            'button_remove_product': 'Entfernen',
            'voucher_form_header': 'Du hast einen Gutschein?',
            'redeem_voucher_label': 'Rabattcode oder Geschenkgutschein',
            'redeem_voucher_placeholder': 'z.B.: dsjk29',
            'button_gift_redeem': 'Einlösen',
            'label_size': 'Größe',
            'label_count': 'Anzahl',
            'text_product_count': '{count} Artikel',
            'item_marker_voucher': 'Gutschein',
            'item_marker_optional': 'Gratis',
            'item_marker_dsv': 'Versicherung',
			'info_savings': 'Du sparst',
			'info_self_pickup': 'Selbstabholung: Kostenlos in der Filiale München-Allach abholen! Wähle die Option im letzten Bestellschritt.',
            mounting: {
                'headline': 'Ski-Montage',
				'headline_bike': 'Bike Einstellung',
                'text_select_mounting': 'Bitte Montage konfigurieren',
                'text_select_mounting_bike': 'Bitte ergänze noch Dein Körpergewicht, damit wir das Bike optimal für Dich einstellen können.',
                'button_configure': 'Montage konfigurieren',
                'button_configure_bike': 'Bike Einstellung konfigurieren',
                'button_edit': 'Montage bearbeiten',
                'button_edit_bike': 'Bike Einstellung bearbeiten',
                'button_select_no_mounting':  'Ohne Montage',
                'button_select_no_mounting_bike':  'Ohne Voreinstellung',
                'text_no_mounting_wanted':  'Du hast Dich entschieden <strong>keine</strong> Ski-Montage vornehmen zu lassen.',
                'text_no_mounting_wanted_bike':  'Du hast Dich entschieden <strong>keine</strong> Dämpfereinstellung für Dein Körpergewicht vornehmen zu lassen.',
                'text_mountpoint': 'Montagepunkt',
                'text_shoe_length': 'Sohlenlänge',
                'text_shoe_size': 'Schuhgröße',
                'text_mounting_by_shoe': 'Montage erfolgt anhand des ausgewählten Schuhs',
                'text_rider_weight': 'Dämpfereinstellung',
            },
        },
        checkout: {
            confirmation: {
                'headline_check_order': 'Bestellung überprüfen',
                'title_shipping_address': 'Lieferadresse',
                'title_billing_address': 'Rechnungsadresse',
                'title_payment_method': 'Bezahlung',
                'headline_order_items': 'Deine Artikel',
                'text_newsletter': 'Ja, ich möchte mich zum Sport Bittl Newsletter anmelden und keine Angebote mehr verpassen.',
                'text_agbs': 'Ja, ich stimme den <a href="{link_agb}" target="_blank">AGB</a> und den <span style="text-decoration:underline;"><a href="{link_privacy}" target="_blank">Datenschutzbestimmungen</a></span> zu und ich habe mich über mein <a href="{link_revocation}" target="_blank">Widerrufsrecht</a> informiert!',
                'button_buy_now': 'Jetzt kaufen',
            }
        },
        shipping_info: {
            description: "Bitte beachte, dass sich die Lieferzeiten Wochenend- bzw. Feiertagsbedingt und für Fahrräder seitens der Spedition verzögern können. <br><br>" +
                "Der <strong>Samstag</strong> wird von unseren Versanddienstleistern <strong>nicht als Werktag</strong> berücksichtigt. <a href='/de/infos/faqs.html' target='_blank'>Mehr Infos</a>",
			self_pickup_description: "<strong>Kostenlos vor Ort abholen!</strong> <br>" +
				"Wähle im letzten Schritt des Bestellvorgangs die Option 'Selbstabholung' und hole Deine Bestellung bequem und kostenlos in unserer Filiale München-Allach ab.",
        },
        address_book: {
            'billing_address_is_shipping_address': 'Rechnungsadresse entspricht der Lieferadresse',
            'select_different_billing_address': 'Abweichende Rechnungsadresse auswählen',
            'header_new_address': 'Neue Adresse',
            'text_new_address': 'Du möchtest eine neue Adresse hinzufügen?',
            'confirm_delete': 'Bist du sicher?',
            'button_change_address': 'Adresse ändern',
            'button_use_address': 'Adresse verwenden',
            'button_cancel': 'Abbrechen',
            'button_edit': 'Bearbeiten',
            'button_save': 'Speichern',
            'button_delete': 'Löschen',
            'button_close': 'Schließen',
            'button_add_address': 'Adresse hinzufügen',
            'button_submit_address_selection': 'Bestätigen',
        },
        signup_form: {
            'button_signup': 'Jetzt anmelden!',
        },
        newsletter_form: {
            'confirmation_headline': 'Bitte bestätige Deine Anmeldung!',
            'confirmation_text': '<p>Um die Anmeldung abzuschließen, klicke bitte auf den Link in der E-Mail, die wir soeben an Dich gesendet haben.</p><p>Viele Grüße <br>Dein Sport Bittl Team</p>',
        },
        textarea: {
            'chars_left': 'Zeichen übrig',
        },
        bike_mounting_config: {
            overlay_headline: 'Bike Einstellung bearbeiten',
            overlay_info_banner: ' Bei Fragen melde Dich telefonisch bei unseren Experten der <strong>Bike Hotline</strong> unter +49 89 89219-193 von Montag bis Freitag 10:00 - 17:00 Uhr.',
            label_weight: 'Dämpfereinstellung',
            placeholder_weight: 'z.B.: 75kg',
            description_weight: 'Die korrekte und für Dich passende Einstellung von Federgabel und gegebenenfalls Dämpfer ist wichtig und individuell, da sie in Abhängigkeit von Deinem Körpergewicht erfolgt. \n Eine gut eingestellte Federgabel bzw. ein optimal justierter Dämpfer sind beim Mountainbiken unverzichtbar, eine schlechte Einstellung kann sogar zu Stürzen führen.',
        },
        ski_mounting_config: {
            overlay_headline: 'Ski-Montage konfigurieren',
            overlay_info_banner: 'Bei Fragen melde Dich telefonisch bei unseren Experten der <strong>Ski Hotline</strong> unter +49 89 89219-193 von Montag bis Freitag 10:00 - 17:00 Uhr.',
            shoe_is_set: 'Montage erfolgt anhand des ausgewählten Schuhs !',
            montage_line_mid: 'Skimitte',
            montage_line_manufacturer: 'Herstellerempfehlung',
            montage_line_choice: 'Deine Einstellung',
            montage_pull_subtext: 'Bei der Auswahl "Benötige Beratung" wird Dich einer unserer Monteure kontaktieren',
            montage_head: 'Montage',
            // montage_sohle: 'Sohlenlänge (mm)',
            mount_point: 'Montagepunkt',
            montage_no: 'keine Montage',
            montage_sole: 'Sohlenlänge in mm',
            montage_shoesize: 'Schuhgröße',
            montage_sole_text: 'Die Sohlenlänge entspricht der gemessenen Länge der Skischuhsohle in mm! (siehe Abb.1) ',
            button_save_config: 'speichern',
            cc_su_us_man: 'US-Herren',
            cc_su_us_woman: 'US-Damen',
            montage_choose: 'Wünschst Du eine Montage',
            montage_choose_yes: 'Ja',
            montage_choose_no: 'Nein',
            montage_choose_choose: 'wählen',
            messages: {
                select_sole: "Bitte trage Deine Sohlenlänge ein und klick auf \"Speichern\".",
                select_shoesize: "Bitte trage Deine Schuhgröße ein und klick auf \"Speichern\".",
                adjusted_sole: "Deine Sohlenlänge wurde angepasst.",
                adjusted_shoesize: "Deine Schuhgröße wurde angepasst.",
                no_return_for_mounted_ski: '<strong>Hinweis</strong>:<br> Ski, die passend zu Deine Angaben montiert und eingestellt werden, sind von einer Rückgabe ausgeschlossen. Genaue Infos findest Du in unseren <a href="/de/infos/faqs.html#re0">FAQ</a> oder <a href="/de/agb:_:3.html">AGB</a>.'
            }
        },
        wishlist: {
            wishlist: 'Wunschliste',
            wishlist_login_banner: '<strong>Info:</strong> Um Deine Artikel dauerhaft zu speichern und alle Funktionen der Wunschliste zu nutzen - <a href="/login.php">jetzt einloggen</a> oder <a href="/login.php#form-register">registrieren</a>.',
            wishlist_empty: 'Deine Wunschliste ist leer.',
            select_size_first: 'Bitte wähle zuerst eine Größe aus',
            select_size_in_setconfig: 'Größen im Setkonfigurator auswählen',
            save_to_wishlist: 'Auf Wunschliste merken',
            save_to_wishlist_short: 'Wunschliste',
            saved_on_wishlist: 'Auf Wunschliste gemerkt',
            saved_on_wishlist_long: 'Der Artikel wurde erfolgreich auf der Wunschliste gemerkt',
            item_sold_out: 'Der Artikel ist leider ausverkauft',
            item_sold_out_set: 'Bitte Set überprüfen und fertigstellen',
            item_sold_out_size: 'Größe derzeit nicht verfügbar',
            finalize_set: 'Set fertigstellen',
            show_sizes: 'Größen anzeigen'
        },
        product_sizes: {
            label: "Größe auswählen",
            // label_no_selection: "Bitte wähle zuerst eine Größe aus",
            label_no_selection: "Größe auswählen",
            loading: 'wird geladen …',
            no_store_stock: 'Nur online verfügbar',
            text_store_availability_explanation_no_size:  "Bitte wähle oben eine Größe aus. Für diese Größe findest Du hier die Filialverfügbarkeit, die stündlich aktualisiert wird.",
            text_store_availability_explanation_with_size:  "Die Filialverfügbarkeit des Produktes in der gewählten Größe wird mehrmals stündlich aktualisiert.",
        },
		product_comparison: {
			listing_toggle: 'Produkt vergleichen',
			message_comparison_full_head: 'Produktvergleich',
			message_comparison_full: 'Deine Vergleichsliste ist leider schon voll.<br> Entferne ein oder mehrere Produkte um weitere hinzufügen zu können',
			go_to_comparison: 'Zum Produktvergleich',
		}
    },
};

module.exports = btlang;

// use like
//btlang.messages.error.invalidEmail
