"use strict";

import {nodeListToArray} from "./helpers";

const defaults = {
  selectors: {
    notificationContainer: '.notification-container',
    notification: '.notification',
    message: '.message',
  },
  fadeOutTime : 2000,
};

const state = {
  notifications: [],
  isNotificationsInitialized: false,
};

export class Notification {
  constructor(domEl) {
    this.el = domEl;

    console.log(this.el);
    this.shouldFadeOut = typeof (this.el.dataset.fadeout) !== 'undefined' ;

    if (this.shouldFadeOut) {
      this.fadeOutTime = !isNaN(parseFloat(this.el.dataset.fadeout)) ? parseFloat(this.el.dataset.fadeout) * 1000 : defaults.fadeOutTime;
    }
    this.closeButton = this.el.querySelector('.delete');
    this.timeOut = null;
    this.bindActions();
  }

  bindActions() {
    let that = this;
    if (this.closeButton) {
        this.closeButton.addEventListener('click', that.removeNotification.bind(this));
    }

    if(this.shouldFadeOut) {
      this.timeOut = setTimeout(that.removeNotification.bind(this), that.fadeOutTime);
    }
  }

  removeNotification() {

    if (this.el) {
      if (this.closeButton) {
          this.closeButton.removeEventListener('click', this.remove);
      }
      clearTimeout(this.timeOut);
      this.el.parentNode.removeChild(this.el);
    }
  }
}

export default function initNotifications () {
  const notificationContainerEl = document.querySelector(defaults.selectors.notificationContainer);

  if (!notificationContainerEl) {
    return false;
  }

  const notificationEls = nodeListToArray(notificationContainerEl.querySelectorAll(defaults.selectors.notification));
  const messageEls = nodeListToArray(notificationContainerEl.querySelectorAll(defaults.selectors.message));

	notificationEls.forEach((notificationEl) => {
		let notification = new Notification(notificationEl);
		state.notifications.push(notification);
	});

	messageEls.forEach((messageEl) => {
		let notification = new Notification(messageEl);
		state.notifications.push(notification);
	});

  state.isNotificationsInitialized = true;
}
