"use strict";
//
import {lang} from "./helpers";

const strings_de = require('./../../lang/lang_german.js');
const strings_en = require('./../../lang/lang_english.js');

const defaults = {
    availableLangs: [
        'de', 'en'
    ]
};

export default function getLangStrings (specificLang) {

    if (!lang && !specificLang) {
        return false;
    }

    let langToGet = specificLang || lang;

    let exportLangStrings = null;

    switch (langToGet) {
        case 'de':
            exportLangStrings = strings_de;
            break;
        case 'en':
            exportLangStrings = strings_en;
            break;
        default:
            break;
    }

    return exportLangStrings
}
