var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "signupForm",
      staticClass: "signup-form",
      attrs: { id: "signup-form" },
    },
    [
      _vm.errors.length > 0
        ? _c("div", { staticClass: "errors" }, [
            _c(
              "div",
              { staticClass: "notification is-warning" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("messages.errors.formError")) +
                    "\n            "
                ),
                _vm._l(_vm.errors, function (error, index) {
                  return _c("span", [
                    index !== 0 ? _c("span", [_vm._v(", ")]) : _vm._e(),
                    _vm._v(_vm._s(error)),
                  ])
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form__fieldgroup-row" }, [
        _c(
          "div",
          { staticClass: "signup-form__fieldgroup form__fieldgroup is-half" },
          [
            _c(
              "label",
              {
                staticClass: "signup-form__label form__label",
                attrs: { for: "signup-form__gender" },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("field_labels.gender")) +
                    " "
                ),
                _c("span", { staticClass: "marker--required" }, [_vm._v("*")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.customerData.gender,
                    expression: "customerData.gender",
                  },
                ],
                staticClass:
                  "signup-form__input form__select form__input select-input text-input u-full-width",
                attrs: {
                  name: "gender",
                  id: "signup-form__gender",
                  required: "",
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.customerData,
                      "gender",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { attrs: { value: "" } }),
                _vm._v(" "),
                _c("option", { attrs: { value: "f" } }, [
                  _vm._v(_vm._s(_vm.$t("field_labels.female"))),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "m" } }, [
                  _vm._v(_vm._s(_vm.$t("field_labels.male"))),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form__fieldgroup-row" }, [
        _c("div", { staticClass: "signup-form__fieldgroup form__fieldgroup" }, [
          _c(
            "label",
            {
              staticClass: "signup-form__label form__label",
              attrs: { for: "signup-form__first-name" },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("field_labels.first_name")) +
                  " "
              ),
              _c("span", { staticClass: "marker--required" }, [_vm._v("*")]),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.customerData.firstName,
                expression: "customerData.firstName",
              },
            ],
            staticClass:
              "signup-form__input form__input text-input u-full-width",
            attrs: {
              type: "text",
              placeholder: _vm.$t("field_labels.first_name"),
              id: "signup-form__first-name",
              name: "firstname",
              required: "",
              title: "title",
            },
            domProps: { value: _vm.customerData.firstName },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.customerData, "firstName", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "signup-form__fieldgroup form__fieldgroup" }, [
          _c(
            "label",
            {
              staticClass: "signup-form__label form__label",
              attrs: { for: "signup-form__last-name" },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("field_labels.last_name")) +
                  "  "
              ),
              _c("span", { staticClass: "marker--required" }, [_vm._v("*")]),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.customerData.lastName,
                expression: "customerData.lastName",
              },
            ],
            staticClass:
              "signup-form__input form__input text-input u-full-width",
            attrs: {
              type: "text",
              placeholder: _vm.$t("field_labels.last_name"),
              id: "signup-form__last-name",
              name: "Address lastname",
              required: "",
            },
            domProps: { value: _vm.customerData.lastName },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.customerData, "lastName", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form__fieldgroup-row" }, [
        _c(
          "div",
          { staticClass: "signup-form__fieldgroup form__fieldgroup is-half" },
          [
            _c(
              "label",
              {
                staticClass: "signup-form__label form__label",
                attrs: { for: "signup-form__date_of_birth" },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("field_labels.date_of_birth")) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.customerData.date_of_birth,
                  expression: "customerData.date_of_birth",
                },
              ],
              staticClass:
                "signup-form__input form__input text-input u-full-width",
              attrs: {
                type: "date",
                placeholder: _vm.$t("field_labels.date_of_birth"),
                id: "signup-form__date_of_birth",
                name: "Address date_of_birth",
              },
              domProps: { value: _vm.customerData.date_of_birth },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.customerData,
                    "date_of_birth",
                    $event.target.value
                  )
                },
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form__fieldgroup-row" }, [
        _c("div", { staticClass: "signup-form__fieldgroup form__fieldgroup" }, [
          _c(
            "label",
            {
              staticClass: "signup-form__label form__label",
              attrs: { for: "signup-form__street" },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("field_labels.street")) +
                  "  "
              ),
              _c("span", { staticClass: "marker--required" }, [_vm._v("*")]),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.customerData.street,
                expression: "customerData.street",
              },
            ],
            staticClass:
              "signup-form__input form__input text-input u-full-width",
            attrs: {
              type: "text",
              placeholder: _vm.$t("field_labels.street"),
              id: "signup-form__street",
              name: "Address streetaddress",
              maxlength: "50",
              required: "",
            },
            domProps: { value: _vm.customerData.street },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.customerData, "street", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "signup-form__fieldgroup form__fieldgroup" }, [
          _c(
            "label",
            {
              staticClass: "signup-form__label form__label",
              attrs: { for: "signup-form__street-number" },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("field_labels.streetNumber")) +
                  "  "
              ),
              _c("span", { staticClass: "marker--required" }, [_vm._v("*")]),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.customerData.streetNumber,
                expression: "customerData.streetNumber",
              },
            ],
            staticClass:
              "signup-form__input form__input text-input u-full-width",
            attrs: {
              type: "text",
              placeholder: _vm.$t("field_labels.streetNumber"),
              id: "signup-form__street-number",
              name: "Address street_number",
              required: "",
            },
            domProps: { value: _vm.customerData.streetNumber },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.customerData, "streetNumber", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form__fieldgroup-row" }, [
        _c(
          "div",
          { staticClass: "signup-form__fieldgroup form__fieldgroup is-half" },
          [
            _c(
              "label",
              {
                staticClass: "signup-form__label form__label",
                attrs: { for: "signup-form__country" },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("field_labels.country")) +
                    "  "
                ),
                _c("span", { staticClass: "marker--required" }, [_vm._v("*")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedCountryID,
                    expression: "selectedCountryID",
                  },
                ],
                staticClass:
                  "signup-form__input form__select form__input select-input text-input u-full-width",
                attrs: {
                  type: "text",
                  id: "signup-form__country",
                  name: "Address country_id",
                  required: "",
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedCountryID = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                !_vm.selectedCountryID || _vm.selectedCountryID === 0
                  ? _c("option", { attrs: { value: "" } }, [_vm._v("---")])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.countryOptions, function (country) {
                  return _c("option", { domProps: { value: country.id } }, [
                    _vm._v(_vm._s(country.name_de)),
                  ])
                }),
              ],
              2
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form__fieldgroup-row" }, [
        _c("div", { staticClass: "signup-form__fieldgroup form__fieldgroup" }, [
          _c(
            "label",
            {
              staticClass: "signup-form__label form__label",
              attrs: { for: "signup-form__zip" },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("field_labels.postal_code")) +
                  "  (" +
                  _vm._s(_vm.$t("helpers.e_g")) +
                  ' : "' +
                  _vm._s(_vm.selectedCountry.format_display) +
                  '")\n                '
              ),
              _c("span", { staticClass: "marker--required" }, [_vm._v("*")]),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.customerData.zip,
                expression: "customerData.zip",
              },
            ],
            staticClass:
              "signup-form__input form__input text-input u-full-width",
            attrs: {
              type: "text",
              placeholder: _vm.$t("field_labels.postal_code"),
              id: "signup-form__zip",
              name: "Address postcode",
              pattern: _vm.preparePattern(_vm.selectedCountry.zip_regex),
              maxlength: _vm.selectedCountry.format_display
                ? _vm.selectedCountry.format_display.length
                : false,
              required: "",
            },
            domProps: { value: _vm.customerData.zip },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.customerData, "zip", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "signup-form__fieldgroup form__fieldgroup" }, [
          _c(
            "label",
            {
              staticClass: "signup-form__label form__label",
              attrs: { for: "signup-form__city" },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("field_labels.city")) +
                  "  "
              ),
              _c("span", { staticClass: "marker--required" }, [_vm._v("*")]),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.customerData.city,
                expression: "customerData.city",
              },
            ],
            staticClass:
              "signup-form__input form__input text-input u-full-width",
            attrs: {
              type: "text",
              placeholder: _vm.$t("field_labels.city"),
              id: "signup-form__city",
              name: "Address city",
              required: "",
            },
            domProps: { value: _vm.customerData.city },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.customerData, "city", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form__fieldgroup-row" }, [
        _c("div", { staticClass: "signup-form__fieldgroup form__fieldgroup" }, [
          _c(
            "label",
            {
              staticClass: "signup-form__label form__label",
              attrs: { for: "signup-form__email" },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("field_labels.email")) +
                  "  "
              ),
              _c("span", { staticClass: "marker--required" }, [_vm._v("*")]),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.customerData.email,
                expression: "customerData.email",
              },
            ],
            staticClass:
              "signup-form__input form__input text-input u-full-width",
            attrs: {
              type: "email",
              placeholder: _vm.$t("field_labels.email"),
              id: "signup-form__email",
              name: "email",
              required: "",
            },
            domProps: { value: _vm.customerData.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.customerData, "email", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "signup-form__fieldgroup form__fieldgroup" }, [
          _c(
            "label",
            {
              staticClass: "signup-form__label form__label",
              attrs: { for: "signup-form__tel" },
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("field_labels.telephone")) +
                  "  "
              ),
              _c("span", { staticClass: "marker--required" }, [_vm._v("*")]),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.customerData.telephone,
                expression: "customerData.telephone",
              },
            ],
            staticClass:
              "signup-form__input form__input text-input u-full-width",
            attrs: {
              type: "text",
              placeholder: _vm.$t("field_labels.telephone"),
              id: "signup-form__tel",
              maxlength: "15",
              name: "Address telephone",
              required: "",
            },
            domProps: { value: _vm.customerData.telephone },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.customerData, "telephone", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form__fieldgroup-row" }, [
        _c("div", { staticClass: "signup-form__fieldgroup form__fieldgroup" }, [
          _c(
            "label",
            {
              staticClass: "signup-form__label form__label",
              attrs: { for: "signup-form__new_password" },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("field_labels.new_password")) +
                  "\n                "
              ),
              _c("span", { staticClass: "marker--required" }, [_vm._v("*")]),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.customerData.new_password,
                expression: "customerData.new_password",
              },
            ],
            staticClass:
              "signup-form__input form__input text-input u-full-width",
            attrs: {
              type: "password",
              placeholder: _vm.$t("field_labels.new_password"),
              id: "signup-form__new_password",
              name: "new_password",
              required: "",
            },
            domProps: { value: _vm.customerData.new_password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.customerData, "new_password", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "signup-form__fieldgroup form__fieldgroup" }, [
          _c(
            "label",
            {
              staticClass: "signup-form__label form__label",
              attrs: { for: "signup-form__new_password_confirm" },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("field_labels.new_password_confirm")) +
                  "\n                "
              ),
              _c("span", { staticClass: "marker--required" }, [_vm._v("*")]),
            ]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.customerData.new_password_confirm,
                expression: "customerData.new_password_confirm",
              },
            ],
            staticClass:
              "signup-form__input form__input text-input u-full-width",
            attrs: {
              type: "password",
              placeholder: _vm.$t("field_labels.new_password_confirm"),
              id: "signup-form__new_password_confirm",
              name: "new_password_confirm",
              required: "",
            },
            domProps: { value: _vm.customerData.new_password_confirm },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.customerData,
                  "new_password_confirm",
                  $event.target.value
                )
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form__fieldgroup-row" }, [
        _c("div", { staticClass: "signup-form__fieldgroup form__fieldgroup" }, [
          _c(
            "span",
            { staticClass: "form__sub-fieldgroup u-no-margin-bottom" },
            [
              _c(
                "label",
                {
                  staticClass: "baseinfo-form__label form__label is-inline",
                  attrs: { for: "newsletter_checkin" },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customerData.newsletter_checkin,
                        expression: "customerData.newsletter_checkin",
                      },
                    ],
                    staticClass:
                      "baseinfo-form__input form__radio checkbox-input",
                    attrs: {
                      type: "checkbox",
                      id: "newsletter_checkin",
                      name: "newsletter_checkin",
                      value: "off",
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.customerData.newsletter_checkin
                      )
                        ? _vm._i(_vm.customerData.newsletter_checkin, "off") >
                          -1
                        : _vm.customerData.newsletter_checkin,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.customerData.newsletter_checkin,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "off",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.customerData,
                                "newsletter_checkin",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.customerData,
                                "newsletter_checkin",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.customerData, "newsletter_checkin", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "form__label__title" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("field_labels.newsletter_checkin")) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "form__label__description" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.$t("field_labels.newsletter_header")) +
                        "\n                "
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "signup-form__fieldgroup form__fieldgroup u-no-margin-bottom",
        },
        [
          _c(
            "button",
            {
              staticClass: "button main-action-button",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.signup()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("components.signup_form.button_signup")))]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }