import axios from 'axios';
import {handleError} from "./_shared";

const settings = {
    baseUrl: '/api/customer.php',
}

export default {
    signup(customerData) {

        return new Promise((resolve, reject) =>
            axios.post(settings.baseUrl,
                customerData,
                {
                    params: {
                        action: 'signup',
                        // customerId: address.customerId
                    }
                })
                .then(response => resolve(response.data))

				.catch(function (error) {
					handleError(error, reject);
				})
        )
    },
    checkEmailCC(email) {

        return new Promise((resolve, reject) =>
            axios.get(settings.baseUrl,
                {
                    params: {
                        action: 'checkEmailCC',
                        email: email,
                        // customerId: address.customerId
                    }
                })
                .then(response => resolve(response.data))

				.catch(function (error) {
					handleError(error, reject);
				})
        )
    }
}
