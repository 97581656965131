"use strict";

import {findAncestorByClass, nodeListToArray, addClass, removeClass, isMobile, hasClass, isTouchDevice} from "./helpers";

const defaults = {
    selectors: {
        productNavTriggerElems: '.js-toggle-product-nav',
        productNavElem: '.js-product-nav',
        flyoutTrigger: '.js-flyout-trigger',
        flyoutCloseTrigger: '.js-flyout-close',
        flyout: '.js-flyout-content',
        subFlyoutTriggers: '.js-flyout-trigger-sub',
        desktopSubnav: '.js-product-subnav',
        desktopSubnavToggle: '.js-product-subnav-toggle',
        body: '#top'
    },
    classes: {
        flyoutActiveClass: 'is-open'
    },
    closeDelay: 500,
};

const state = {
    body: false,
    isProductNavOpen: false,
    isProductNavInitialized: false,
    productNavElem: null,
    productNavTriggerElems: null,
    flyoutTriggers: null,
    subFlyoutTriggers: null,
    flyouts: null,
    activeFlyoutTrigger: null,
    activeSubFlyoutTrigger: null,
    desktopSubnavTriggers: null,
    desktopSubnavElems: null,
    activeDesktopSubnavElem: null,
    menuOpenTime: null,
};

function bindTriggers() {
  if (state.productNavTriggerElems === null || state.flyoutTriggers === null) {
    return;
  }

	state.productNavTriggerElems.forEach(function (element) {
		element.addEventListener('click', function (event) {
			event.preventDefault();
			console.log("in event-listener");
			toggleProductNav();
		});
	});

  	state.flyoutTriggers.forEach(function (element) {
		if (!isMobile()) {
			element.addEventListener('mouseenter', function (event) {
				console.log('mouseenter');
				openFlyout(element);
			});
			element.parentNode.addEventListener('mouseleave', function (event) {
				console.log('mouseleave');
				closeFlyout()
			});
		}

		element.addEventListener('click', function (event) {

			if (isMobile()) {
				event.preventDefault();
				toggleFlyout(element);
			} else {
				if(isTouchDevice()) {
					if(state.activeFlyoutTrigger === element) {
						// submenu is visible
						// just go use the link
					} else {
						event.preventDefault();
						openFlyout(element);
					}
				} else {
					if(element.dataset.flyoutMode !== 'hover') {
						event.preventDefault();
					}
				}
			}
		});
	});

	state.flyoutCloseTriggers.forEach(function (element) {
		element.addEventListener('click', function (event) {
			event.preventDefault()
			closeFlyout();
		});
	});

	state.subFlyoutTriggers.forEach(function (element) {
		element.addEventListener('click', function (event) {
			if (isMobile()) {
				event.preventDefault();
				toggleSubFlyout(element);
			}
		});

	});

	state.desktopSubnavTriggers.forEach(function (element) {
		element.addEventListener('click', function (event) {
			if (!isMobile()) {
				event.preventDefault();
				desktopToggleSubnav(element);
			}
		});
	});
}

function toggleProductNav() {
  if (!state.isProductNavInitialized) {
    return;
  }

    console.log("in toggle");

    if (state.isProductNavOpen) {
    closeProductNav();
  } else {
    openProductNav();
  }
}

function toggleFlyout(trigger) {
  if (!state.isProductNavInitialized) {
    return;
  }

  let currentTrigger = trigger;


  if (state.activeFlyoutTrigger === currentTrigger) {
      let now = Date.now();
      if (state.menuOpenTime + defaults.closeDelay >= now) {
          return;
      }

      removeClass(currentTrigger, defaults.classes.flyoutActiveClass);

      let subTriggers = currentTrigger.parentNode.querySelectorAll(defaults.selectors.subFlyoutTriggers);
	  Array.from(subTriggers).forEach(function (element) {
		  removeClass(element, defaults.classes.flyoutActiveClass)
	  });

      state.activeFlyoutTrigger = null;
      state.menuOpenTime = null;
  } else {

      if (state.activeFlyoutTrigger) {
          removeClass(state.activeFlyoutTrigger, defaults.classes.flyoutActiveClass);

          let subTriggers = state.activeFlyoutTrigger.parentNode.querySelectorAll(defaults.selectors.subFlyoutTriggers);
		  Array.from(subTriggers).forEach(function (element) {
			  removeClass(element, defaults.classes.flyoutActiveClass)
		  });
      }
      addClass(currentTrigger, defaults.classes.flyoutActiveClass);
      state.activeFlyoutTrigger = currentTrigger;

      state.menuOpenTime = Date.now();
  }

  if(state.activeFlyoutTrigger) {
      addClass(document.querySelector('body'), 'is-nav-open');
  } else {
      removeClass(document.querySelector('body'), 'is-nav-open');
  }
}

export function openFlyout(trigger) {
	if (!state.isProductNavInitialized) {
		return;
	}

	let currentTrigger = trigger;


	if (state.activeFlyoutTrigger === currentTrigger) {

	} else {

		if (state.activeFlyoutTrigger) {
			removeClass(state.activeFlyoutTrigger, defaults.classes.flyoutActiveClass);

			let subTriggers = state.activeFlyoutTrigger.parentNode.querySelectorAll(defaults.selectors.subFlyoutTriggers);
			Array.from(subTriggers).forEach(function (element) {
				removeClass(element, defaults.classes.flyoutActiveClass)
			});
		}
		addClass(currentTrigger, defaults.classes.flyoutActiveClass);
		state.activeFlyoutTrigger = currentTrigger;

		state.menuOpenTime = Date.now();
	}

	if(state.activeFlyoutTrigger) {
		addClass(document.querySelector('body'), 'is-nav-open');
	}
}

export function closeFlyout() {
    if (!state.isProductNavInitialized) {
        return;
    }

    if(!state.activeFlyoutTrigger) {
        return;
    }

    removeClass(state.activeFlyoutTrigger, defaults.classes.flyoutActiveClass);

    let subTriggers = state.activeFlyoutTrigger.parentNode.querySelectorAll(defaults.selectors.subFlyoutTriggers);
	Array.from(subTriggers).forEach(function (element) {
		removeClass(element, defaults.classes.flyoutActiveClass)
	});


	state.activeFlyoutTrigger = null;
    state.menuOpenTime = null;

    removeClass(document.querySelector('body'), 'is-nav-open');
}

function toggleSubFlyout(trigger) {
  if (!state.isProductNavInitialized) {
    return;
  }

  let currentTrigger = trigger;

    console.log(currentTrigger);

    if (currentTrigger.classList.contains(defaults.classes.flyoutActiveClass)) {
      removeClass(currentTrigger, defaults.classes.flyoutActiveClass);
      removeClass(findAncestorByClass(currentTrigger, 'js-product-subnav'), defaults.classes.flyoutActiveClass);
  } else {
      addClass(findAncestorByClass(currentTrigger, 'js-product-subnav'), defaults.classes.flyoutActiveClass);
      addClass(currentTrigger, defaults.classes.flyoutActiveClass);
  }
}


function desktopToggleSubnav(trigger) {
  if (!state.isProductNavInitialized) {
    return;
  }

  let parentSubNav = findAncestorByClass(trigger, defaults.selectors.desktopSubnav);

  if (parentSubNav.classList.contains(defaults.classes.flyoutActiveClass)) {
      removeClass(parentSubNav, defaults.classes.flyoutActiveClass);
      state.activeDesktopSubnavElem = null;
  } else {
      if(state.activeDesktopSubnavElem) {
        removeClass(state.activeDesktopSubnavElem, defaults.classes.flyoutActiveClass)
      }

      addClass(parentSubNav, defaults.classes.flyoutActiveClass);
      state.activeDesktopSubnavElem = parentSubNav;
  }
}


function openProductNav() {
    if (!state.isProductNavInitialized) {
        return;
    }

    addClass(state.productNavElem, 'is-nav-open');
    addClass(state.body, 'is-product-nav-open');
    state.isProductNavOpen = true;
}

function closeProductNav() {
    if (!state.isProductNavInitialized) {
        return;
    }

    removeClass(state.productNavElem, 'is-nav-open')
    removeClass(state.body, 'is-product-nav-open');
    state.isProductNavOpen = false;
}

export function initProductNav(options) {
    if (state.isProductNavInitialized) {
        return;
    }

    options = defaults;

    state.body = document.querySelector(options.selectors.body);
    state.productNavTriggerElems = nodeListToArray(document.querySelectorAll(options.selectors.productNavTriggerElems));
    state.productNavElem = document.querySelector(options.selectors.productNavElem);
    state.flyoutTriggers = document.querySelectorAll(options.selectors.flyoutTrigger);
    state.flyoutCloseTriggers = document.querySelectorAll(options.selectors.flyoutCloseTrigger);
    state.flyouts = document.querySelectorAll(options.selectors.flyout);

    state.desktopSubnavElems = document.querySelectorAll(options.selectors.desktopSubnav);
    state.desktopSubnavTriggers = document.querySelectorAll(options.selectors.desktopSubnavToggle);

    state.subFlyoutTriggers = document.querySelectorAll(options.selectors.subFlyoutTriggers);

    bindTriggers();

    state.isProductNavInitialized = true;

    console.log(state);

}
