export function handleError(error, reject) {
	if (error.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		console.log(error.response.data);
		console.log(error.response.status);
		console.log(error.response.headers);

		window.Sentry.captureMessage("handleError 1: " + error.response.data);
		reject(error.response.data);
	} else if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		console.log("The request was made but no response was received");
		console.log(error.request);
		window.Sentry.captureMessage("handleError request: " + error.request);
		reject(error.request);

	} else {
		// Something happened in setting up the request that triggered an Error
		console.log('Error', error.message);
		window.Sentry.captureMessage("handleError error: " + error.message);
		reject(error);
	}
}
