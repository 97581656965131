"use strict";

import {nodeListToArray, addClass, removeClass, isMobile, hasClass} from "./helpers";
import merge from 'deepmerge';

const defaults = {
    selectors: {
        accordionContainer: '.js-accordion',

        accordionElement: '.js-accordion-element',
        accordionElementHead: '.js-accordion-element-head',
        accordionElementContent: '.js-accordion-element-content',
        accordionElementToggle: '.js-accordion-element-toggle',

    },
    classes: {
        activeClass: 'is-active',
        initClass: 'is-accordion-initialized'
    },
    setFirstActive: false,
    setAllActive: false,
    closeOthers: true,
};

window.accordionState = {
    accordionContainers: null,
    accordions: [],

    accordionNav: null,
    accordionContentsHolder: null,
};

class Accordion {
    constructor(accordionContainer, options = {}) {
        this.accordionContainer = accordionContainer;
        this.options = merge(defaults, options);

        this.options.setFirstActive = this.accordionContainer.dataset['accordionSetFirstActive'] === 'true';
        this.options.setFirstInactiveMobile = this.accordionContainer.dataset['accordionMobileSetFirstInactive'] === 'true';
        this.options.setAllActive = this.accordionContainer.dataset['accordionSetAllActive'] === 'true';

        if (isMobile() && this.options.setFirstInactiveMobile) {
            this.options.setFirstActive = false
        }

        console.log(this.options);
        this.state = this.setInitialState();
        this.state.options = this.options;

        this.bindToggles();

		this.state.toggleAccordionElement = this.toggleAccordionElementByNode.bind(this);

        console.log(this.state);

        window.accordionState.accordions.push(this.state)

        addClass(this.accordionContainer, this.options.classes.initClass);
    }

    setInitialState() {
        let accordion = {
            container: this.accordionContainer,
            accordionElements: [],
        };

        let accordionElementNodes = this.accordionContainer.querySelectorAll(':scope > ' + this.options.selectors.accordionElement);

        Array.from(accordionElementNodes).forEach((accordionElementNode, index) => {

            let accordionElement = {
                element: accordionElementNode,
                head: accordionElementNode.querySelector(this.options.selectors.accordionElementHead),
                content: accordionElementNode.querySelector(this.options.selectors.accordionElementContent),
                toggle: accordionElementNode.querySelector(this.options.selectors.accordionElementToggle),
                isActive: false
            };

            accordion.accordionElements.push(accordionElement);

            if('#' +  accordionElementNode.id ===  window.location.hash) {
                this.openAccordionElement(accordionElement);
            } else if (this.options.setAllActive) {
                this.openAccordionElement(accordionElement);
            } else if (this.options.setFirstActive && index === 0) {
                this.openAccordionElement(accordionElement);
            } else {
                this.closeAccordionElement(accordionElement);
            }
        });

        return accordion;
    }

    bindToggles() {
        let self = this;
        this.state.accordionElements.forEach(accordionElement => {

            accordionElement.toggle.addEventListener('click', function(e) {
                e.preventDefault();
                self.toggleAccordionElement(accordionElement)
            })
        });

    }

    toggleAccordionElement(accordionElement) {
        let closeOthers = this.accordionContainer.dataset.accordionSingleOpenElement !== 'false'
        if(accordionElement.isActive) {
            this.closeAccordionElement(accordionElement)
        } else {
            if(closeOthers) {
                let currentlyActive = this.getOpenAccordionElement();
                currentlyActive.forEach(accordionElement => {
                    this.closeAccordionElement(accordionElement);
                });
            }
            this.openAccordionElement(accordionElement);
            if (isMobile() && closeOthers) {
                accordionElement.element.scrollIntoView({ behavior: 'smooth', block: "start" });
            }
        }
    }

    openAccordionElement(accordionElement) {
        addClass(accordionElement.element, this.options.classes.activeClass)
        accordionElement.isActive = true;
    }

    closeAccordionElement(accordionElement) {
        removeClass(accordionElement.element, this.options.classes.activeClass)
        accordionElement.isActive = false;
    }

    getOpenAccordionElement() {

        let actives = this.state.accordionElements.filter(accordionElement => accordionElement.isActive);

        return actives;
    }

	toggleAccordionElementByNode(node) {
		let accordionElement = this.state.accordionElements.find(accordionElement => accordionElement.element === node);

		if(accordionElement) {
			this.toggleAccordionElement(accordionElement);
		}
	}
}
export function toggleAccordionElement(selector) {
	let accordionElement = null;
	if(typeof selector === 'string') {
		accordionElement = document.querySelector(selector);
	} else {
		accordionElement = selector;
	}
	if(accordionElement) {
		let accordion = window.accordionState.accordions.find(accordion => accordion.container.contains(accordionElement));
		if(accordion) {
			accordion.toggleAccordionElement(accordionElement);
		}
	}
}

export function initAccordions (parentNode = document, options = {}) {
    let accordionContainers = parentNode.querySelectorAll(defaults.selectors.accordionContainer);

    if (accordionContainers) {
        Array.from(accordionContainers).forEach(accordionContainer => {
            if(!hasClass(accordionContainer, defaults.classes.initClass)) {
                new Accordion(accordionContainer);
            }
        });
    }
}
