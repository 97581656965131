import signupFormApi from '../../api/signup-form'

// initial state
const state = () => ({
    customerData: {},
})

// getters
const getters = {}

// actions
const actions = {

    signup: function ({commit, state}, customerData) {

        return new Promise((resolve, reject) => {
            signupFormApi.signup(customerData)
                .then(response => {
                    if (response.success) {
                        resolve(response);
                    } else {
                        console.error('cant sign up ')
                        reject();
                    }
                })
                .catch(errorResponse => {
                    console.error(errorResponse.errors)
                    reject(errorResponse)
                })
        })
    },
}

// mutations
const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
